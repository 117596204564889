/* eslint-disable react/prop-types */
import { CheckBox } from '@mui/icons-material';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Modal,
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import axios from 'axios';
import useMessageDispatcher from 'hooks/useMessageDispatcher';
import React, { useEffect, useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '80%',
    borderRadius: '16px'
};

const StudentsListModal = ({ open, setOpen, studentsList, values, setFieldValue, moduleId = '', resourceId = '' }) => {
    const [showStudentsList, setShowStudentsList] = useState(false);

    const showMessage = useMessageDispatcher();

    const handleClose = () => {
        setOpen(false);
        setShowStudentsList(false);
    };

    const handleShareWithAllChange = (event) => {
        const { checked } = event.target;
        setShowStudentsList(false);
        setFieldValue('allStudents', checked);
    };

    const handleSelectStudentsClick = () => {
        setShowStudentsList(true);
        setFieldValue('allStudents', false);
    };

    const handleStudentSelection = (checked, studentId) => {
        let modifiedList;
        if (checked) {
            modifiedList = [...values.studentIds, studentId];
        } else {
            modifiedList = values.studentIds?.filter((id) => id !== studentId);
        }

        setFieldValue('studentIds', modifiedList);
    };

    const handleSelectionAll = (checked) => {
        if (checked) {
            const allStudentsIds = studentsList.map((s) => s.id);
            setFieldValue('studentIds', allStudentsIds);
        } else {
            setFieldValue('studentIds', []);
        }
    };

    const { allStudents, studentIds } = values;

    const handleShareResource = async () => {
        try {
            const params = {
                moduleResourceId: resourceId,
                allStudents
            };
            const payload = { studentIds };

            const response = await axios.put(`/v1/subjects/lesson-plans/module/resources/share`, payload, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                },
                params
            });
            if (response.status === 200) {
                if (response.data.code !== 403) {
                    handleClose();
                    showMessage({ message: response.message });
                    setFieldValue('allStudents', false);
                    setFieldValue('studentIds', []);
                } else {
                    showMessage({ message: response.message, color: 'error' });
                }
            }
        } catch (error) {
            if (!error.response) {
                showMessage({ message: error.message, color: 'error' });
            } else {
                showMessage({ message: error.response.data.Msg, color: 'error' });
            }
        }
    };

    useEffect(() => {
        setFieldValue('allStudents', false);
        setFieldValue('studentIds', []);
    }, [resourceId]);

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="students-modal-title">
            <Box sx={style}>
                <Typography id="students-modal-title" variant="h4" component="h2" gutterBottom>
                    Share with students
                </Typography>
                <FormControlLabel
                    control={<Checkbox checked={values?.allStudents} onChange={handleShareWithAllChange} />}
                    label="Share with all students"
                />
                <Button onClick={handleSelectStudentsClick} variant="outlined" sx={{ ml: 2 }}>
                    Select Students
                </Button>
                {showStudentsList && !values?.allStudents && (
                    <TableContainer component={Paper} sx={{ maxHeight: '70vh' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            checked={values.studentIds.length === studentsList.length}
                                            onChange={(e) => handleSelectionAll(e.target.checked)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </TableCell>
                                    <TableCell>Student Name</TableCell>
                                    <TableCell align="right">Enrollment No</TableCell>
                                    <TableCell align="right">Course Type</TableCell>
                                    <TableCell align="right">Course Name</TableCell>
                                </TableRow>
                            </TableHead>
                            {studentsList.map((student, index) => (
                                <TableRow
                                    key={index}
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    // sx={
                                    //     selectedUsersToDelete.includes(user.id) && {
                                    //         backgroundColor: '#ebebeb'
                                    //     }
                                    // }
                                >
                                    <TableCell align="right">
                                        <Checkbox
                                            checked={values.studentIds.includes(student.id)}
                                            onChange={(e) => handleStudentSelection(e.target.checked, student.id)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {student.studentName}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {student.enrollmentNo}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {student.courseType}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {student.courseName}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </Table>
                    </TableContainer>
                )}
                <Button disabled={!allStudents && studentIds.length === 0} sx={{ m: 1 }} variant="contained" onClick={handleShareResource}>
                    Submit
                </Button>
            </Box>
        </Modal>
    );
};

export default StudentsListModal;
