import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const RegistrationCount = Loadable(lazy(() => import('views/dashboard/Institute/RegistrationCount')));
const DepartmentRegistrationCount = Loadable(lazy(() => import('views/dashboard/Institute/DepartmentRegistrationCount')));
const ProgramsRegistrationCount = Loadable(lazy(() => import('views/dashboard/Institute/ProgramsRegistrationCount')));
const DashBoardRegistrationList = Loadable(lazy(() => import('views/dashboard/Institute/DashboardRegistrationList')));
const RegistationDashboard = Loadable(lazy(() => import('views/dashboard/Institute/RegistationDashboard')));
const FeesDahboard = Loadable(lazy(() => import('views/dashboard/Institute/FeesDahboard')));
const FeesByCourseDashboard = Loadable(lazy(() => import('views/dashboard/Institute/FeesByCourseDashboard')));
const StaffDashboard = Loadable(lazy(() => import('views/dashboard/Institute/StaffDashboard')));
const NonTeachingStaff = Loadable(lazy(() => import('views/dashboard/Institute/NonTeachingStaff')));
const AdmissionHandlerDashboard = Loadable(lazy(() => import('views/teacher/admission-handler-dashboard/DashBoard')));
const CancelledAdmissionByDepartment = Loadable(lazy(() => import('views/dashboard/Institute/CancelStudentListByDepartment')));
const ScholarShipByDepartment = Loadable(lazy(() => import('views/dashboard/Institute/ScholarshipStudentListByDepartment')));
const ControllOfExamination = Loadable(lazy(() => import('views/dashboard/control_of_exam/Dashboard')));
const CoachDashBoard = Loadable(lazy(() => import('views/application/sports/coachdashboard/index')));
// const PlacementDashBoard = Loadable(lazy(() => import('views/dashboard/placementdashboard/PlacementDashboard')));

const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const DashboardRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/registration/count',
            element: <RegistrationCount />
        },
        {
            path: 'department/registration/count/:id',
            element: <DepartmentRegistrationCount />
        },
        {
            path: 'program/registration/count/:id',
            element: <ProgramsRegistrationCount />
        },
        {
            path: '/dashboard/registration/list/:id',
            element: <DashBoardRegistrationList />
        },
        {
            path: '/registation/dashboard',
            element: <RegistationDashboard />
        },
        {
            path: '/registation/dashboard',
            element: <RegistationDashboard />
        },
        {
            path: '/fees/dashboard',
            element: <FeesDahboard />
        },
        {
            path: '/fees/course/:feeCode',
            element: <FeesByCourseDashboard />
        },
        {
            path: '/staff/dashboard',
            element: <StaffDashboard />
        },
        {
            path: '/staff/nonteaching',
            element: <NonTeachingStaff />
        },
        {
            path: '/admission/handler/dashboard',
            element: <AdmissionHandlerDashboard />
        },
        {
            path: '/cancelled/admission/bydepartment/:batchId',
            element: <CancelledAdmissionByDepartment />
        },
        {
            path: '/scholarship/bydepartment/:batchId',
            element: <ScholarShipByDepartment />
        },
        {
            path: '/coe/dashboard',
            element: <ControllOfExamination />
        },
        {
            path: '/coach/dashboard',
            element: <CoachDashBoard />
        }
        // {
        //     path: '/placement/dashboard',
        //     element: <PlacementDashBoard />
        // }
    ]
};
export default DashboardRoutes;
