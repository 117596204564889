/* eslint-disable prettier/prettier */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const AppraisalFormBuilder = Loadable(lazy(() => import('views/application/appraisal/index')));
const AppraisalQuestionsList = Loadable(lazy(() => import('views/application/appraisal/QuestionIndex')));
const EditQuestions = Loadable(lazy(() => import('views/application/appraisal/EditQuestion')));
const AppraisalApprovalFlow = Loadable(lazy(() => import('views/application/appraisal/apraisalapprovalflow/index')));
const EditAppraisalApprovalFlow = Loadable(lazy(() => import('views/application/appraisal/apraisalapprovalflow/Add')));
const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const AppraisalRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/appraisal/form',
            element: <AppraisalFormBuilder />
        },
        {
            path: '/appraisal/questions/list',
            element: <AppraisalQuestionsList />
        },
        {
            path: '/edit/questions/:id',
            element: <EditQuestions />
        },
        {
            path: '/appraisal/approval/flow',
            element: <AppraisalApprovalFlow />
        },
        {
            path: '/edit/appraisal/approval/flow/:id',
            element: <EditAppraisalApprovalFlow />
        }
    ]
};
export default AppraisalRoutes;
