import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

// project imports
import { BASE_PATH } from 'config';
import { gridSpacing } from 'store/constant';

// assets
import { IconTallymark1 } from '@tabler/icons';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';

const linkSX = {
    display: 'flex',
    color: 'grey.900',
    textDecoration: 'none',
    alignContent: 'center',
    alignItems: 'center'
};

let dashBoard = '';

if (localStorage.getItem('role') === 'ROLE_STUDENT_ADMISSION') {
    dashBoard = '/student/list';
}

if (localStorage.getItem('role') === 'ROLE_COLLEGE_ADMIN') {
    dashBoard = '/college/dashboard';
}
if (localStorage.getItem('role') === 'ROLE_ADMIN') {
    dashBoard = '/institute/dashboard';
}
if (localStorage.getItem('role') === 'ROLE_SUPER_ADMIN') {
    dashBoard = '/dashboard/default';
}

// ==============================|| BREADCRUMBS ||============================== //

const Breadcrumbs = ({ card, divider, icons, maxItems, navigation, separator, title, titleBottom, ...others }) => {
    const theme = useTheme();
    const location = useLocation();
    const [breadcrumbTrail, setBreadcrumbTrail] = useState([]);

    const iconStyle = {
        marginRight: theme.spacing(0.75),
        marginTop: `-${theme.spacing(0.25)}`,
        width: '16px',
        height: '16px',
        color: theme.palette.secondary.main
    };

    const getBreadcrumbTrail = (menu) => {
        if (menu.children) {
            menu.children.forEach((collapse) => {
                if (collapse.type === 'collapse') {
                    getBreadcrumbTrail(collapse);
                } else if (collapse.type === 'item') {
                    if (location.pathname === BASE_PATH + collapse.url) {
                        setBreadcrumbTrail([collapse, ...(collapse.parent ? [collapse.parent] : []), menu]);
                    }
                }
            });
        }
    };

    useEffect(() => {
        setBreadcrumbTrail([]);

        navigation?.items?.forEach((menu) => {
            if (menu.type === 'group') {
                getBreadcrumbTrail(menu);
            }
        });
    }, [location.pathname, navigation]);

    const SeparatorIcon = separator;
    const separatorIcon = separator ? <SeparatorIcon stroke={1.5} size="18px" /> : <IconTallymark1 stroke={1.5} size="18px" />;

    let breadcrumbContent = <Typography />;

    if (breadcrumbTrail.length > 0) {
        const [item, main, topMenu] = breadcrumbTrail;

        const CollapseIcon = main?.icon ? main.icon : AccountTreeTwoToneIcon;
        const ItemIcon = item?.icon ? item.icon : AccountTreeTwoToneIcon;

        const mainContent = topMenu && (
            <Typography component={Link} to="#" variant="h4" sx={linkSX}>
                {icons && <CollapseIcon style={iconStyle} />}
                {topMenu.title}
            </Typography>
        );
        const mainIcon = topMenu && <img src={topMenu.icon} alt={mainContent} width={40} />;
        const itemContent = item && (
            <Typography
                variant="h4"
                sx={{
                    display: 'flex',
                    textDecoration: 'none',
                    alignContent: 'center',
                    alignItems: 'center',
                    color: 'grey.500'
                }}
            >
                {icons && <ItemIcon style={iconStyle} />}
                {item.title}
            </Typography>
        );
        breadcrumbContent = (
            <Card
                sx={{
                    marginBottom: card === false ? 0 : theme.spacing(gridSpacing),
                    border: card === false ? 'none' : '1px solid',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75,
                    background: card === false ? 'transparent' : theme.palette.background.default
                }}
                {...others}
            >
                <Box sx={{ p: 2, pl: card === false ? 0 : 2 }}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                        <Grid item>{mainIcon}</Grid>
                        <Grid item>
                            <MuiBreadcrumbs
                                sx={{ '& .MuiBreadcrumbs-separator': { width: 16, ml: 1.25, mr: 1.25 } }}
                                aria-label="breadcrumb"
                                maxItems={maxItems || 8}
                                separator={separatorIcon}
                            >
                                {mainContent}
                                {itemContent}
                            </MuiBreadcrumbs>
                        </Grid>
                        {title && titleBottom && (
                            <Grid item>
                                <Typography variant="h3" sx={{ fontWeight: 500 }}>
                                    {item.title}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
                {card === false && divider !== false && <Divider sx={{ borderColor: theme.palette.primary.main, mb: gridSpacing }} />}
            </Card>
        );
    }

    return breadcrumbContent;
};

Breadcrumbs.propTypes = {
    card: PropTypes.bool,
    divider: PropTypes.bool,
    icon: PropTypes.bool,
    icons: PropTypes.bool,
    maxItems: PropTypes.number,
    navigation: PropTypes.object,
    rightAlign: PropTypes.bool,
    separator: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    title: PropTypes.bool,
    titleBottom: PropTypes.bool
};

export default Breadcrumbs;
