import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const Teams = Loadable(lazy(() => import('views/teams')));
const TeamMembers = Loadable(lazy(() => import('views/teams/Team')));

const roles = ['ROLE_STUDENT', 'ROLE_STUDENT_ADMISSION', 'ROLE_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN', 'ROLE_ADMIN'];

const TeamRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/teams/add',
            element: <Teams />
        },
        {
            path: '/teams/add-members/:id/:supervisorId',
            element: <TeamMembers />
        }
    ]
};
export default TeamRoutes;
