/* eslint-disable eqeqeq */
import LAYOUT_CONST from 'constant';

export const JWT_API = {
    secret: 'SECRET-KEY',
    timeout: '1 days'
};

export const FIREBASE_API = {
    apiKey: 'AIzaSyBernKzdSojh_vWXBHt0aRhf5SC9VLChbM',
    authDomain: 'berry-material-react.firebaseapp.com',
    projectId: 'berry-material-react',
    storageBucket: 'berry-material-react.appspot.com',
    messagingSenderId: '901111229354',
    appId: '1:901111229354:web:a5ae5aa95486297d69d9d3',
    measurementId: 'G-MGJHSL8XW3'
};

export const AUTH0_API = {
    client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
    domain: 'dev-w0-vxep3.us.auth0.com'
};

export const AWS_API = {
    poolId: 'us-east-1_AOfOTXLvD',
    appClientId: '3eau2osduslvb7vks3vsh9t7b0'
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

let dashBoard = '';

if (localStorage.getItem('role') === 'ROLE_STUDENT_ADMISSION') {
    dashBoard = '/student/registration';
}

if (localStorage.getItem('role') === 'ROLE_HIRER') {
    dashBoard = '/company/dashboard';
}

if (localStorage.getItem('role') === 'ROLE_COLLEGE_ADMIN') {
    dashBoard = '/institute/dashboard';
}
if (localStorage.getItem('role') === 'ROLE_ADMIN') {
    dashBoard = '/institute/dashboard';
}
if (localStorage.getItem('role') === 'ROLE_SUPER_ADMIN') {
    dashBoard = '/dashboard/default';
}
if (localStorage.getItem('role') === 'ROLE_COLLEGE_ADMIN' && localStorage.getItem('userTypes') === 'Dean') {
    dashBoard = '/dean/dashboard';
}
if (localStorage.getItem('role') === 'ROLE_COLLEGE_ADMIN' && localStorage.getItem('userTypes') === 'Dean') {
    dashBoard = '/dean/dashboard';
}
if (
    localStorage.getItem('role') === 'ROLE_COLLEGE_SUB_ADMIN' &&
    localStorage.getItem('userTypes') !== 'HOD' &&
    localStorage.getItem('userTypes') !== 'Accountant' &&
    localStorage.getItem('userTypes') !== 'Admission_Handler' &&
    localStorage.getItem('userTypes') !== 'Registrar' &&
    localStorage.getItem('userTypes') !== 'DUC' &&
    localStorage.getItem('userTypes') !== 'COE' &&
    localStorage.getItem('userTypes') !== 'COACH' &&
    localStorage.getItem('userTypes') !== 'PLACEMENT_IN_CHARGE'
) {
    dashBoard = '/teacher/dashboard';
}
if (localStorage.getItem('userTypes') === 'LIBRARIAN') {
    dashBoard = '/library/dashboard/admin';
}
if (localStorage.getItem('userTypes') === 'HOD' && localStorage.getItem('role') === 'ROLE_COLLEGE_SUB_ADMIN') {
    dashBoard = '/hod/dashboard';
}
if (localStorage.getItem('userTypes') === 'Accountant' && localStorage.getItem('role') === 'ROLE_COLLEGE_SUB_ADMIN') {
    dashBoard = '/accountant/dashboard';
}
if (localStorage.getItem('userTypes') === 'Registrar' && localStorage.getItem('role') === 'ROLE_COLLEGE_SUB_ADMIN') {
    dashBoard = '/registrar/dashboard';
}
if (localStorage.getItem('userTypes') === 'Admission_Handler' && localStorage.getItem('role') === 'ROLE_COLLEGE_SUB_ADMIN') {
    dashBoard = '/admission/dashboard';
}
if (localStorage.getItem('userTypes') === 'DUC' && localStorage.getItem('role') === 'ROLE_COLLEGE_SUB_ADMIN') {
    dashBoard = '/duc/dashboard';
}
if (localStorage.getItem('userTypes') === 'COE' && localStorage.getItem('role') === 'ROLE_COLLEGE_SUB_ADMIN') {
    dashBoard = '/coe/dashboard';
}
if (localStorage.getItem('userTypes') === 'COACH' && localStorage.getItem('role') === 'ROLE_COLLEGE_SUB_ADMIN') {
    dashBoard = '/coach/dashboard';
}
if (localStorage.getItem('userTypes') === 'PLACEMENT_IN_CHARGE' && localStorage.getItem('role') === 'ROLE_COLLEGE_SUB_ADMIN') {
    dashBoard = '/placement/dashboard';
}
if (localStorage.getItem('role') === 'ROLE_STUDENT') {
    dashBoard = '/student/dashboard';
}

export const DASHBOARD_PATH = dashBoard;
export const HORIZONTAL_MAX_ITEM = 6;

const config = {
    layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
    drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
    fontFamily: `'Plus Jakarta Sans', sans-serif`,

    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;
