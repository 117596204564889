/* eslint-disable array-callback-return */
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';

// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
import User1 from 'assets/images/users/user-round.svg';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ notification, handleClose, clearNotifications }) => {
    const navigate = useNavigate();
    const convertEpochToGMT = (epochTime) => {
        const date = new Date(epochTime * 1000);
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };
        return date.toLocaleString('en-US', options);
    };
    return (
        <Box>
            {notification?.map((elem) => (
                <List
                    onClick={() => {
                        if (elem?.notificationRegarding === 'LIBRARY') {
                            navigate('/books/add-reviews');
                            clearNotifications([elem?.id]);
                            handleClose();
                        }
                    }}
                >
                    <ListItemWrapper>
                        <ListItem alignItems="center">
                            <ListItemAvatar>
                                <Avatar alt="Rate Book" src="/assets/library-icons/book.jpg" />
                            </ListItemAvatar>
                            <ListItemText primary={elem?.title} />
                            <ListItemSecondaryAction>
                                <Grid container justifyContent="flex-end">
                                    <Grid item xs={12}>
                                        <Typography variant="caption" display="block" gutterBottom>
                                            {convertEpochToGMT(elem?.timestamp)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Grid container spacing={3} display="flex" justifyContent="space-between" alignItems="center">
                            <Grid item xs={10} sm={10} md={10} sx={{ mt: 1 }}>
                                <Typography variant="subtitle2">{elem?.message}</Typography>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} sx={{ mt: 1 }}>
                                <Tooltip title="Clear">
                                    <IconButton onClick={() => clearNotifications([elem?.id])}>
                                        <HighlightOffIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </ListItemWrapper>
                    <Divider />
                </List>
            ))}
        </Box>
    );
};

export default NotificationList;
