/* eslint-disable no-use-before-define */
/* eslint-disable react/button-has-type */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import useMessageDispatcher from 'hooks/useMessageDispatcher';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import ModalComponent from 'reusable-components/ModalComponent';
import ExamScreen from 'reusable-components/exam-screen/ExamScreen';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

// import * as faceapi from 'face-api.js';

const StartExam = () => {
    const showMessage = useMessageDispatcher();

    // const { id, answerSheetId } = useParams();
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [assignmentDetail, setassignmentDetail] = useState([]);
    const [allAnswers, setAllAnswers] = useState({});
    const [isModalOpenSubmit, setIsModalOpenSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [webcamStream, setWebcamStream] = useState(null);

    const state = location.state;
    console.log(state, 'xxxxxxx');
    const { duration, id, answerSheetId, remainingDuration } = state;
    // const { duration, id, answerSheetId } = state?.entranceExam;
    console.log(state, duration, remainingDuration, 'In side exam panel');

    useEffect(() => {
        const targetRoute = `/student/start-exam/${id}/${answerSheetId}`;

        if (location.pathname === targetRoute) {
            localStorage.setItem('exammode', 'true');
        } else {
            localStorage.setItem('exammode', 'false');
        }

        return () => {
            localStorage.setItem('exammode', 'false');
        };
    }, [location.pathname, id, answerSheetId]);

    console.log(id, answerSheetId, 'answerSheetId');

    const getAssignment = async () => {
        try {
            const response = await axios.get(`/v1/assignments/question-student/list?assignmentId=${id}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            });

            setassignmentDetail(response.data?.questionList);
        } catch (error) {
            if (!error.response) {
                showMessage({ message: error.message, type: 'error' });
            } else {
                showMessage({ message: error.response.data.Msg, type: 'error' });
            }
        }
    };
    useEffect(() => {
        getAssignment();
    }, []);

    const handleNextQuestion = () => {
        setCurrentQuestion((prev) => prev + 1);
    };

    const handlePreviousQuestion = () => {
        setCurrentQuestion((prev) => Math.max(0, prev - 1));
    };

    useEffect(() => {
        if (assignmentDetail.length === 0) {
            getAssignment();
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            descriptiveAnswers: '',
            selectedOptions: [],
            selectMcqAnswer: null,
            questionId: assignmentDetail[currentQuestion]?.id ?? ''
        },

        onSubmit: async (values) => {
            let payLoad;
            const chosenAnsersModified = values.selectMcqAnswer === null ? [] : [values.selectMcqAnswer];

            if (assignmentDetail[currentQuestion]?.questionPaperType === 'DESCRIPTIVE') {
                payLoad = {
                    answerId: 0,
                    questionId: assignmentDetail[currentQuestion]?.id ?? '',
                    descriptive: true,
                    answerText: values.descriptiveAnswers,
                    assignmentId: id,
                    answerSheetId
                };
            } else if (assignmentDetail[currentQuestion]?.questionPaperType === 'MULTIPLE_CHOICE') {
                payLoad = {
                    answerId: 0,
                    questionId: assignmentDetail[currentQuestion]?.id ?? '',
                    chosenAnswers: chosenAnsersModified,
                    descriptive: false,
                    answerText: '',
                    assignmentId: id,
                    answerSheetId
                };
            } else if (assignmentDetail[currentQuestion]?.questionPaperType === 'MULTI_ANSWER') {
                payLoad = {
                    answerId: 0,
                    questionId: assignmentDetail[currentQuestion]?.id ?? '',
                    chosenAnswers: values.selectedOptions[currentQuestion] || [],
                    descriptive: false,
                    answerText: '',
                    assignmentId: id,
                    answerSheetId
                };
            }
            setLoading(true);

            try {
                const response = await axios.post(`/v1/assignments/answer`, payLoad, {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                        'Content-Type': 'application/json'
                    }
                });
                if (response.status === 200) {
                    console.log(response, 'res+++');
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: response.data?.Msg,
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    setLoading(false);
                }
            } catch (error) {
                if (!error.response) {
                    showMessage({ message: error.message, type: 'error' });
                } else {
                    showMessage({ message: error.response.data.Msg, type: 'error' });
                }
                setLoading(false);
            }
            setAllAnswers((prevAnswers) => ({
                ...prevAnswers,
                [currentQuestion]: {
                    descriptiveAnswers: values.descriptiveAnswers,
                    selectedOptions: values.selectedOptions,
                    selectMcqAnswer: values.selectMcqAnswer,
                    questionId: values.questionId,
                    questionType: assignmentDetail[currentQuestion]?.questionPaperType
                }
            }));
            if (currentQuestion !== assignmentDetail.length - 1) {
                handleNextQuestion();
            }
            if (currentQuestion === assignmentDetail.length - 1) {
                console.log('FINAL');
                setIsModalOpenSubmit(true);
            }
        }
    });
    useEffect(() => {
        if (assignmentDetail.length > 0) {
            const initialValues = {
                descriptiveAnswers: '',
                selectedOptions: [],
                selectMcqAnswer: null,
                questionId: assignmentDetail[currentQuestion]?.id ?? '',
                questionType: assignmentDetail[currentQuestion]?.questionPaperType
            };

            const storedAnswer = allAnswers[currentQuestion];
            if (storedAnswer) {
                initialValues.descriptiveAnswers = storedAnswer.descriptiveAnswers || '';
                initialValues.selectedOptions = storedAnswer.selectedOptions || [];
                initialValues.selectMcqAnswer = storedAnswer.selectMcqAnswer || null;
                initialValues.questionId = storedAnswer.questionId || '';
            }

            formik.setValues(initialValues);
        }
    }, [assignmentDetail, currentQuestion, allAnswers]);

    const handleFinalSubmition = async () => {
        const payload = {
            studentId: state?.studentIds,
            rollNo: '',
            studentName: state?.studentNamee,
            fileUpload: '',
            uploadFile: false,
            finalSubmission: true
        };
        console.log(payload, 'pay');
        try {
            const response = await axios.post(`/v1/assignments/start?assignmentId=${id}`, payload, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                console.log('response', response);
                // Set a flag indicating the exam has been submitted
                window.localStorage.setItem('examSubmitted', 'true');

                setIsModalOpenSubmit(false);
                navigate('/student/Exam', { state: { answerSheetId } });

                if (document.fullscreenElement) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.webkitExitFullscreen) {
                        document.webkitExitFullscreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                }
                if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                    navigator.mediaDevices
                        .getUserMedia({ video: false, audio: false })
                        .then((stream) => {
                            stream.getTracks().forEach((track) => track.stop());
                        })
                        .catch((error) => {
                            console.error('Error revoking webcam and audio access:', error);
                        });
                }
                window.location.reload();
            }
        } catch (error) {
            if (!error.response) {
                showMessage({ message: error.message, type: 'error' });
            } else {
                showMessage({ message: error.response.data.Msg, type: 'error' });
            }
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const enterFullScreen = () => {
        const element = document.documentElement;

        if (element.requestFullscreen) {
            element.requestFullscreen();
            // } else if (element.mozRequestFullScreen) {
            //     element.mozRequestFullScreen();
            // } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        enterFullScreen();
    };

    useEffect(() => {
        const handleFullScreenChange = () => {
            if (!document.fullscreenElement && !isModalOpen) {
                setIsModalOpen(true);
            }
        };

        const handleKeyDown = (event) => {
            if (event.key === 'Escape' && !isModalOpen) {
                setIsModalOpen(true);
            }
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('keydown', handleKeyDown);

        // Enter fullscreen mode when the component mounts
        enterFullScreen();

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen]);

    // Proctoring

    useEffect(() => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices
                .getUserMedia({ video: true })
                .then((stream) => {
                    setWebcamStream(stream);
                    startObjectDetection(stream); // Start object detection once webcam stream is available
                })
                .catch((error) => console.error('Error accessing webcam:', error));
        }
    }, []);

    const startObjectDetection = (stream) => {
        cocoSsd
            .load()
            .then((model) => {
                const video = document.createElement('video');
                video.srcObject = stream;
                video.addEventListener('loadedmetadata', () => {
                    video.play();
                    detectFrame(video, model);
                });
            })
            .catch((error) => console.error('Error loading COCO-SSD model:', error));

        // Load face detection model
        faceapi.nets.tinyFaceDetector.loadFromUri('/models');
    };

    const detectFrame = (video, model) => {
        model
            .detect(video)
            .then((objectPredictions) => {
                // Handle object detection predictions
                console.log('Object Predictions:', objectPredictions);
                // Perform actions based on object predictions

                // Start face detection
                faceapi
                    .detectAllFaces(video)
                    .withFaceLandmarks()
                    .then((facePredictions) => {
                        // Handle face detection predictions
                        console.log('Face Predictions:', facePredictions);
                        // Perform actions based on face predictions

                        // Request next frame
                        requestAnimationFrame(() => {
                            detectFrame(video, model);
                        });
                    })
                    .catch((error) => console.error('Error detecting faces:', error));
            })
            .catch((error) => console.error('Error detecting frame:', error));
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <ExamScreen
                    questionList={assignmentDetail}
                    remainingDuration={remainingDuration}
                    currentQuestion={currentQuestion}
                    allAnswers={allAnswers}
                    formik={formik}
                    setCurrentQuestion={setCurrentQuestion}
                    handleFinalSubmition={handleFinalSubmition}
                    state={state}
                />
            </form>
            <ModalComponent
                isOpen={isModalOpenSubmit}
                onClose={() => setIsModalOpenSubmit(false)}
                title="Are sure want to submit exam?"
                onConfirm={() => handleFinalSubmition()}
            />
        </>
    );
};
export default StartExam;
