import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Link component={RouterLink} to={DASHBOARD_PATH}>
        <div style={{ padding: 2 }}>
            <img src="/asset/entrar@university.svg" alt="entrar@university" height="60%" width="100%" />
        </div>
    </Link>
);

export default LogoSection;
