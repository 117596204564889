import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const AdminDashboard = Loadable(lazy(() => import('views/lms/admin/Dashboard')));
const TeacherDashboard = Loadable(lazy(() => import('views/teacher/dashboard/new-dashboard')));
const StudentDashboard = Loadable(lazy(() => import('views/lms/student/Dashboard')));

const LessionPlan = Loadable(lazy(() => import('views/application/lessionPlan')));
const LessonPlanTracking = Loadable(lazy(() => import('views/application/lessionPlan/LessonPlanTracking')));

const Assignment = Loadable(lazy(() => import('views/assignment')));
const AssignmentSetup = Loadable(lazy(() => import('views/assignment/Main')));
const EvaluationAssignments = Loadable(lazy(() => import('views/assest-assignments/assignment-evaluation')));
const EvaluateAssignment = Loadable(lazy(() => import('views/assest-assignments')));
const AnswerSheet = Loadable(lazy(() => import('views/assest-assignments/assignment-evaluation/AnswerSheet')));

const ExamSetUP = Loadable(lazy(() => import('views/exam-setup')));
const ExamScreenShots = Loadable(lazy(() => import('views/exam-screenshorts')));
const QuestionType = Loadable(lazy(() => import('views/exam-setup/QuestionType')));

const StudentAssignments = Loadable(lazy(() => import('views/student/assignment')));
const AssignmentDetail = Loadable(lazy(() => import('views/student/assignment/AssignmentDetail')));
const StartAssignment = Loadable(lazy(() => import('views/student/assignment/StartAssignment')));
const FinalResult = Loadable(lazy(() => import('views/student/assignment/FinalResult')));
const StudentAnswerSheet = Loadable(lazy(() => import('views/student/assignment/AnswerSheet')));

const ExamSetup = Loadable(lazy(() => import('views/student/online-exam')));
const SystemCheck = Loadable(lazy(() => import('views/student/online-exam/SystemCheck')));
const Instructions = Loadable(lazy(() => import('views/student/online-exam/instructions')));
const ValidatePage = Loadable(lazy(() => import('views/student/online-exam/Validate')));
const ExamDetails = Loadable(lazy(() => import('views/student/online-exam/ExamDetails')));
const StartExam = Loadable(lazy(() => import('views/student/online-exam/StartExam')));

const roles = ['ROLE_STUDENT', 'ROLE_STUDENT_ADMISSION', 'ROLE_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN', 'ROLE_ADMIN'];

const LMSRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        // DASHBOARDS
        {
            path: '/lms/dashboard/admin',
            element: <TeacherDashboard />
        },
        {
            path: '/lms/dashboard/teacher',
            element: <TeacherDashboard />
        },
        {
            path: '/lms/dashboard/student',
            element: <StudentDashboard />
        },
        // CURRICULUM
        {
            path: '/lms/lesson-plan',
            element: <LessionPlan />
        },
        {
            path: '/lms/lesson/plan/tracking',
            element: <LessonPlanTracking />
        },
        // ASSIGNMENT
        {
            path: '/lms/add/assignment',
            element: <Assignment />
        },
        {
            path: '/lms/studentList/byassignments/:id',
            element: <AssignmentSetup />
        },
        {
            path: '/lms/assignment/evaluate',
            element: <EvaluateAssignment />
        },
        {
            path: '/lms/individual-student/assignments',
            element: <EvaluationAssignments />
        },
        {
            path: '/lms/assignment/answer-sheet',
            element: <AnswerSheet />
        },
        // EXAM
        {
            path: '/lms/exam',
            element: <ExamSetUP />
        },
        {
            path: '/lms/exam/screen-shots',
            element: <ExamScreenShots />
        },
        {
            path: '/lms/exam/addquestions',
            element: <QuestionType />
        },
        // STUDENT LMS
        {
            path: '/lms/curriculum/student',
            element: <ExamScreenShots />
        },
        {
            path: '/lms/assignment/student',
            element: <StudentAssignments />
        },
        {
            path: '/student/assignment-details/:id/:studentNamee/:studentIds',
            element: <AssignmentDetail />
        },
        {
            path: '/student/start-assignment/:id/:answerSheetId',
            element: <StartAssignment />
        },
        {
            path: '/student/result',
            element: <FinalResult />
        },
        {
            path: '/student/answer-sheet',
            element: <StudentAnswerSheet />
        },
        // STUDENT EXAM
        {
            path: '/lms/assessment/student',
            element: <ExamSetup />
        },
        {
            path: '/systemcheck',
            element: <SystemCheck />
        },
        {
            path: '/validate',
            element: <ValidatePage />
        },
        {
            path: '/instructions',
            element: <Instructions />
        },
        {
            path: '/student/exam-details/:id/:studentNamee/:studentIds',
            element: <ExamDetails />
        },
        {
            path: '/student/start-exam/:id/:answerSheetId',
            element: <StartExam />
        }
    ]
};
export default LMSRoutes;
