import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// Department
const DepartmentList = Loadable(lazy(() => import('views/college/department/index')));
const Courses = Loadable(lazy(() => import('views/college/course/index')));
const Section = Loadable(lazy(() => import('views/college/section/index')));
const AcademyYear = Loadable(lazy(() => import('views/college/academyYear/index')));
const EditAcademy = Loadable(lazy(() => import('views/college/academyYear/Edit')));
const Document = Loadable(lazy(() => import('views/college/Document/index')));
// const AcademyYear = Loadable(lazy(() => import('views/college/academyYear/index')));
const AdmissionStatus = Loadable(lazy(() => import('views/college/admissionStatus/index')));
const StaffType = Loadable(lazy(() => import('views/college/staffCategory/index')));
const Subject = Loadable(lazy(() => import('views/college/subjects/index')));
const Admission = Loadable(lazy(() => import('views/college/admission/academicYear/index')));
const AdmissionList = Loadable(lazy(() => import('views/college/admission/admission-list/index')));
const Details = Loadable(lazy(() => import('views/college/admission/details/index')));
const MenuSubject = Loadable(lazy(() => import('views/college/subjectmenu/index')));
const StaffNewregister = Loadable(lazy(() => import('views/college/staff/index')));
const StaffEdit = Loadable(lazy(() => import('views/college/staff/StaffEdit')));
const AcademicYaerDetails = Loadable(lazy(() => import('views/college/academyYear/details/DetailsList')));
const AddAcountingDivision = Loadable(lazy(() => import('views/college/feeacounting/index')));
const EditAcountingDivesion = Loadable(lazy(() => import('views/college/feeacounting/EditAcountingDivision')));
const AddReceipt = Loadable(lazy(() => import('views/college/receiptbook/index')));
const EditReceipt = Loadable(lazy(() => import('views/college/receiptbook/EditReceipt')));
// const EntranceExams = Loadable(lazy(() => import('views/college/entranceExams/EntranceExams')));
const EntranceExams = Loadable(lazy(() => import('views/college/entranceExams/index')));
const EditEntranceExam = Loadable(lazy(() => import('views/college/entranceExams/EditEntranceExam')));
const StudentProfile = Loadable(lazy(() => import('views/college/admission/admission-list/StudentProfile')));
const Quota = Loadable(lazy(() => import('views/college/quota/index')));
const Qualification = Loadable(lazy(() => import('views/college/qualification/index')));
const Fee = Loadable(lazy(() => import('views/college/fee/index')));
const FeeMaping = Loadable(lazy(() => import('views/college/feeMaping/index')));
const FeeFine = Loadable(lazy(() => import('views/college/feeFine/index')));
const AssignTimeSlotGroup = Loadable(lazy(() => import('views/application/assigntimeslotgroup/AssignTimeSlotGroup')));

const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];
const CollegeRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/department',
            element: <DepartmentList />
        },
        {
            path: '/college/course',
            element: <Courses />
        },
        {
            path: '/sections',
            element: <Section />
        },
        {
            path: '/academicyear',
            element: <AcademyYear />
        },
        {
            path: '/document',
            element: <Document />
        },
        {
            path: '/admission/status',
            element: <AdmissionStatus />
        },
        {
            path: '/stafftype',
            element: <StaffType />
        },
        {
            path: '/college/subject/:id/:sem',
            element: <Subject />
        },
        {
            path: '/admission',
            element: <Admission />
        },
        {
            path: '/admission/list/:id',
            element: <AdmissionList />
        },
        {
            path: '/details/:id',
            element: <Details />
        },
        {
            path: '/course/:id',
            element: <Courses />
        },
        {
            path: '/college/academy/edit/:id',
            element: <EditAcademy />
        },
        {
            path: 'menusubject',
            element: <MenuSubject />
        },
        {
            path: '/staffs/register',
            element: <StaffNewregister />
        },
        {
            path: '/staffs/register/edit',
            element: <StaffEdit />
        },
        {
            path: '/academicyear/details',
            element: <AcademicYaerDetails />
        },
        {
            path: '/acounting/add',
            element: <AddAcountingDivision />
        },
        {
            path: '/acounting/edit/:id',
            element: <EditAcountingDivesion />
        },
        {
            path: '/receipt/add',
            element: <AddReceipt />
        },
        {
            path: '/receipt/edit/:id',
            element: <EditReceipt />
        },
        {
            path: '/entranceexam',
            element: <EntranceExams />
        },
        {
            path: '/college/entrance/edit/:id',
            element: <EditEntranceExam />
        },
        {
            path: '/student/profiile/:id',
            element: <StudentProfile />
        },
        {
            path: '/quota',
            element: <Quota />
        },
        {
            path: '/qualification',
            element: <Qualification />
        },
        {
            path: '/fee/code',
            element: <Fee />
        },
        {
            path: '/fee/maping',
            element: <FeeMaping />
        },
        {
            path: '/fee/fine',
            element: <FeeFine />
        },
        {
            path: '/assign/timeslot/group',
            element: <AssignTimeSlotGroup />
        }
    ]
};

export default CollegeRoutes;
