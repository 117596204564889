import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// Department
const AdmissionDashboard = Loadable(lazy(() => import('views/admission-dashboard')));
const AdmissionDashboardTemporary = Loadable(lazy(() => import('views/admission-dashboard-temporary')));
const AdmissionList = Loadable(lazy(() => import('views/admission/admission-list/index')));
const QuestionType = Loadable(lazy(() => import('views/entranceexam/QuestionType')));
const AdmissionTeam = Loadable(lazy(() => import('views/admission-team')));
const CounsellorsDashboard = Loadable(lazy(() => import('views/counsellors-dashboard')));
const EnquiryData = Loadable(lazy(() => import('views/admission-dashboard/EnquiryData')));
const MediaLinks = Loadable(lazy(() => import('views/admission-dashboard/Components/MediaLinks')));
const TeamMembers = Loadable(lazy(() => import('views/admission-team/team-members')));
const AdmissionProcess = Loadable(lazy(() => import('views/admission-process')));
const AdmissionProcessTeams = Loadable(lazy(() => import('views/admission-process/teams')));
const StepsDetails = Loadable(lazy(() => import('views/admission-process/StepsDetails')));
const TeamHandlingSteps = Loadable(lazy(() => import('views/admission-process/teams/TeamHandlingSteps')));
const RankList = Loadable(lazy(() => import('views/rank-list')));
const ShortlistedApplicants = Loadable(lazy(() => import('views/rank-list/ShortlistedApplicants')));
const AdmissionTracker = Loadable(lazy(() => import('views/admission-tracker')));
const ReAssignStudents = Loadable(lazy(() => import('views/admission-dashboard/ReAssignStudents')));
const StudentProfile = Loadable(lazy(() => import('views/admission/admission-list/StudentProfile')));
const StudentFollowup = Loadable(lazy(() => import('views/admission/admission-list/followup/FollowUps')));
const EntranceExams = Loadable(lazy(() => import('views/entranceexam/index')));
const EntranceExamEvaluation = Loadable(lazy(() => import('views/evaluation-entrance-exam')));
const EditEntranceExam = Loadable(lazy(() => import('views/entranceexam/EditEntranceExam')));
const HallTicket = Loadable(lazy(() => import('views/hallticketlist/index')));
const MeritList = Loadable(lazy(() => import('views/MeritList/index')));
const ShortListedList = Loadable(lazy(() => import('views/MeritList/ShortListedList')));
const AdmissionPayment = Loadable(lazy(() => import('views/studentadmissionProfile/AdmissionPayment')));
const InternalAssessment = Loadable(lazy(() => import('views/examAssement/index')));
const EditInternalAssessment = Loadable(lazy(() => import('views/examAssement/AssessmentEdit')));
const IASubjectAllocation = Loadable(lazy(() => import('views/iaSubjectAllocation/index')));
const InternalAssessmentMarksGroup = Loadable(lazy(() => import('views/examAssement/InternalAssessmentMarksGroup')));
const EditIASubjectAllocation = Loadable(lazy(() => import('views/iaSubjectAllocation/EditIaSubjectAllocation')));
const SubjectiveObjectiveGroup = Loadable(lazy(() => import('views/subjectiveobjectivegroup/index')));
const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const AdmissionRouts = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/admission/dashboard/individual',
            element: <AdmissionDashboardTemporary />
        },
        {
            path: '/admission/dashboard/',
            element: <AdmissionDashboard />
        },
        {
            path: '/admission/list',
            element: <AdmissionList />
        },
        {
            path: '/applicant/followup/:userId/:applicationId',
            element: <StudentFollowup />
        },
        {
            path: '/admission/student/profiile/:id/:applicationId',
            element: <StudentProfile />
        },
        {
            path: '/admission/entranceexam',
            element: <EntranceExams />
        },
        {
            path: '/admission/entrance/edit/:id',
            element: <EditEntranceExam />
        },
        {
            path: '/admission/entrance-exam/question-paper-creation/:id',
            element: <QuestionType />
        },
        {
            path: '/admission/entranceexam/hallticket/:id/:courseName',
            element: <HallTicket />
        },
        {
            path: '/admission/entranceexam/meritlist/:id/:courseName',
            element: <MeritList />
        },
        {
            path: '/admission/entranceexam/shortlisted/list/:id',
            element: <ShortListedList />
        },
        {
            path: '/admission/payment',
            element: <AdmissionPayment />
        },
        {
            path: '/internal/assessment/marks/group',
            element: <InternalAssessmentMarksGroup />
        },
        {
            path: '/subjective/objective/group',
            element: <SubjectiveObjectiveGroup />
        },
        {
            path: '/internal/assessment',
            element: <InternalAssessment />
        },
        {
            path: '/internal/assessment/edit/:id',
            element: <EditInternalAssessment />
        },
        {
            path: '/ia/subjects/allocation',
            element: <IASubjectAllocation />
        },
        {
            path: '/edit/ia/subjects/allocation/:id',
            element: <EditIASubjectAllocation />
        },
        {
            path: '/admissions/team',
            element: <AdmissionTeam />
        },
        {
            path: '/add-members/:id',
            element: <TeamMembers />
        },
        {
            path: '/admission-process/master',
            element: <AdmissionProcess />
        },
        {
            path: '/admission-process/master/:id',
            element: <AdmissionProcessTeams />
        },
        {
            path: '/admission-process/steps/:id',
            element: <StepsDetails />
        },
        {
            path: '/admission-process/team/steps-info/:id',
            element: <TeamHandlingSteps />
        },
        {
            path: '/rank-list',
            element: <RankList />
        },
        {
            path: '/shortlisted-applicants/:id',
            element: <ShortlistedApplicants />
        },
        {
            path: '/counsellor/dashboard',
            element: <CounsellorsDashboard />
        },
        {
            path: '/entrance-exam/evaluation/:id',
            element: <EntranceExamEvaluation />
        },
        {
            path: '/admission/tracker',
            element: <AdmissionTracker />
        },
        {
            path: '/my-students',
            element: <ReAssignStudents />
        },
        {
            path: '/enquiry-data',
            element: <EnquiryData />
        },
        {
            path: '/source-links',
            element: <MediaLinks />
        }
    ]
};

export default AdmissionRouts;
