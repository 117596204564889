import axios from 'axios';
import useMessageDispatcher from 'hooks/useMessageDispatcher';
import React, { useEffect, useState } from 'react';
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Modal,
    Typography,
    Fade,
    Backdrop,
    List,
    ListItem,
    ListItemText,
    Link as MuiLink,
    ListSubheader,
    Button,
    Grid,
    IconButton
} from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ShareIcon from '@mui/icons-material/Share';
import StudentsListModal from './StudentsListModal';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

const ResourceList = ({ moduleId, fetchResourcesByModuleId, resourceList, setResourceList, studentsList, setFieldValue, values }) => {
    const [selectedResource, setSelectedResource] = useState(null);
    const [openStudentsModal, setOpenStudentsModal] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [resourceId, setResourceId] = useState('');

    const showMessage = useMessageDispatcher();
    // useEffect(() => {
    //     fetchResourcesByModuleId(moduleId);
    // }, [moduleId]);

    const handleResourceClick = (resource) => {
        setSelectedResource(resource);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedResource(null);
    };

    const renderThumbnail = (content) => {
        switch (content.resourceType) {
            case 'IMAGE':
                return <CardMedia component="img" width="10%" height="10%" image={content.resourceContent} alt={content.resourceCaption} />;
            case 'PDF':
                return <CardMedia component="img" height="140" src="src/assets/images/custom/PDF-thumbnail.png" alt="PDF Thumbnail" />;
            case 'LIST':
                return (
                    <CardContent>
                        <Typography variant="body2" color="text.secondary">
                            {content.resourceCaption}
                        </Typography>
                    </CardContent>
                );
            case 'TEXT':
                return (
                    <CardContent>
                        <Typography variant="body2" color="text.secondary">
                            {content.resourceContent}
                        </Typography>
                    </CardContent>
                );
            case 'LINK':
                return (
                    <CardContent>
                        <MuiLink href={content.resourceCaption} target="_blank" rel="noopener">
                            {content.resourceContent}
                        </MuiLink>
                    </CardContent>
                );
            default:
                return null;
        }
    };

    const renderExpandedContent = (content) => {
        switch (content.resourceType) {
            case 'IMAGE':
                return (
                    <CardMedia
                        component="img"
                        height="auto"
                        image={content.resourceContent}
                        alt={content.resourceCaption}
                        style={{ margin: 2 }}
                    />
                );
            case 'PDF':
                return (
                    <iframe title="pdf" src={content.resourceContent} width="100%" height="600px" frameBorder="0" style={{ margin: 2 }} />
                );
            case 'LIST':
                return (
                    <List
                        sx={{
                            width: '100%',
                            maxWidth: 360,
                            bgcolor: 'background.paper',
                            position: 'relative',
                            overflow: 'auto',
                            maxHeight: 300,
                            m: 2,
                            '& ul': { padding: 0 }
                        }}
                        subheader={<li />}
                    >
                        <ul>
                            <ListSubheader>{content.resourceCaption}</ListSubheader>
                            {JSON.parse(content.resourceContent).map((item, itemIndex) => (
                                <ListItem key={`item-${itemIndex}-${item}`}>
                                    <ListItemText primary={`* ${item}`} />
                                </ListItem>
                            ))}
                        </ul>
                    </List>
                );
            case 'TEXT':
                return (
                    <CardContent sx={{ m: 2 }}>
                        <Typography variant="body2" color="text.secondary">
                            {content.resourceContent}
                        </Typography>
                    </CardContent>
                );
            case 'LINK':
                return (
                    <CardContent sx={{ m: 2 }}>
                        <MuiLink href={content.resourceCaption} target="_blank" rel="noopener">
                            {content.resourceContent}
                        </MuiLink>
                    </CardContent>
                );
            default:
                return null;
        }
    };

    const updateResourceOrder = async (sourceIndex, destinationIndex) => {
        try {
            const updatedResources = [...resourceList];

            const draggedResource = updatedResources.splice(sourceIndex, 1)[0];

            updatedResources.splice(destinationIndex, 0, draggedResource);

            const updatedResourceOrder = updatedResources.map((resource, index) => ({
                id: resource.id,
                seq: index + 1
            }));

            console.log(updatedResourceOrder, 'updatedResourceOrder');

            const response = await axios.put('/v1/subjects/lesson-plans/module/resources/re-order', updatedResourceOrder, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Resource order updated successfully',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false,
                        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                        transition: 'SlideLeft'
                    })
                );
                // showMessage({ message: 'Resource order updated successfully', color: 'success' });
            } else {
                showMessage({ message: 'Failed to update resource order', color: 'error' });
            }
        } catch (error) {
            showMessage({ message: 'An error occurred while updating resource order', color: 'error' });
        }
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(resourceList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        const updatedResources = items.map((item, index) => ({
            ...item,
            seq: index + 1
        }));

        setResourceList(updatedResources);
    };

    const handleShareEvent = (event, resource) => {
        event.stopPropagation();
        setOpenStudentsModal(true);
        setResourceId(resource.id);
    };

    useEffect(() => {
        if (resourceList.length > 0) {
            updateResourceOrder();
        }
    }, [resourceList]);

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="resourceList" direction="horizontal">
                    {(provided) => (
                        <Box
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            sx={{ display: 'flex', flexWrap: 'wrap', height: '100%', justifyContent: 'center' }} // Adjust flex properties here
                        >
                            {resourceList?.map((resource, index) => (
                                <Draggable key={resource.id} draggableId={resource.id} index={index}>
                                    {(provided) => (
                                        <Card
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            sx={{
                                                maxWidth: 250,
                                                margin: '8px',
                                                cursor: 'pointer',
                                                backgroundColor: '#e3f2fd',
                                                p: 2,
                                                height: 'auto',
                                                maxHeight: 200,
                                                overflowY: 'auto'
                                            }}
                                            onClick={() => handleResourceClick(resource)}
                                        >
                                            <CardActionArea sx={{ height: '100%' }}>
                                                <CardContent>
                                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography gutterBottom variant="h5" component="div">
                                                            {resource.resourceTitle}
                                                        </Typography>

                                                        <IconButton
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={(e) => handleShareEvent(e, resource)}
                                                            size="small"
                                                        >
                                                            <ShareIcon fontSize="tiny" />
                                                        </IconButton>
                                                    </Grid>
                                                    {/* <Typography variant="body2" color="text.secondary">
                                                        // Render other resource details here
                                                    </Typography> */}
                                                </CardContent>
                                                {resource.resourceContents.map((content, index) => (
                                                    <React.Fragment key={index}>{renderThumbnail(content)}</React.Fragment>
                                                ))}
                                            </CardActionArea>
                                            {/* <>
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        // bottom: 0,
                                                        // right: 0,
                                                        transform: 'translateX(-50%)',
                                                        zIndex: 1,
                                                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                                        p: 1,
                                                        borderRadius: '0 0 8px 8px'
                                                    }}
                                                >
                                                    <Button variant="contained" color="primary">
                                                        {resource.resourceTitle}
                                                    </Button>
                                                </Box>
                                            </> */}
                                        </Card>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Box>
                    )}
                </Droppable>
            </DragDropContext>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={openModal}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'white',
                            p: 4,
                            borderRadius: '8px',
                            maxWidth: '80vw',
                            maxHeight: '80vh',
                            overflowY: 'auto'
                        }}
                    >
                        {selectedResource &&
                            selectedResource.resourceContents.map((content, index) => (
                                <React.Fragment key={index}>{renderExpandedContent(content)}</React.Fragment>
                            ))}
                    </Box>
                </Fade>
            </Modal>
            {openStudentsModal && (
                <StudentsListModal
                    open={openStudentsModal}
                    setOpen={setOpenStudentsModal}
                    studentsList={studentsList}
                    values={values}
                    setFieldValue={setFieldValue}
                    moduleId={moduleId}
                    resourceId={resourceId}
                />
            )}
        </Box>
    );
};

export default ResourceList;
