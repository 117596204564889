import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { QualificationContext } from 'views/college/qualification/store/QualificationContextProvider';
import ProtectedRoute from './ProtectedRoute';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const CollegeDashboard = Loadable(lazy(() => import('views/dashboard/College/index')));
const InstituteDashboard = Loadable(lazy(() => import('views/dashboard/Institute/index')));
const ExamDashboard = Loadable(lazy(() => import('views/dashboard/examdashboard/index')));
const SchoolExamDashboard = Loadable(lazy(() => import('views/dashboard/examdashboard/Schoolwisedata')));
const CourseExamDashboard = Loadable(lazy(() => import('views/dashboard/examdashboard/CoursewiseData')));
const DepartmentExamDashboard = Loadable(lazy(() => import('views/dashboard/examdashboard/DepartwiseData')));
const DashboardAnalytics = Loadable(lazy(() => import('views/dashboard/Analytics')));
const StudentCountBuyCourseType = Loadable(lazy(() => import('views/dashboard/Institute/StudentCountByUGPG')));
const TotalStudentByCourseType = Loadable(lazy(() => import('views/dashboard/Institute/TotalStudentCountByCourseType')));

// widget routing
const WidgetStatistics = Loadable(lazy(() => import('views/widget/Statistics')));
const WidgetData = Loadable(lazy(() => import('views/widget/Data')));
const WidgetChart = Loadable(lazy(() => import('views/widget/Chart')));

// application - user social & account profile routing
const AppUserSocialProfile = Loadable(lazy(() => import('views/application/users/social-profile')));
const AppUserAccountProfile1 = Loadable(lazy(() => import('views/application/users/account-profile/Profile1')));
const AppUserAccountProfile2 = Loadable(lazy(() => import('views/application/users/account-profile/Profile2')));
const AppUserAccountProfile3 = Loadable(lazy(() => import('views/application/users/account-profile/Profile3')));
const ChangeProfilePicture = Loadable(lazy(() => import('views/application/users/account-profile/Profile3/ChangeProfile')));

// application - user cards & list variant routing
const AppProfileCardStyle1 = Loadable(lazy(() => import('views/application/users/card/CardStyle1')));
const AppProfileCardStyle2 = Loadable(lazy(() => import('views/application/users/card/CardStyle2')));
const AppProfileCardStyle3 = Loadable(lazy(() => import('views/application/users/card/CardStyle3')));
const AppProfileListStyle1 = Loadable(lazy(() => import('views/application/users/list/Style1')));
const AppProfileListStyle2 = Loadable(lazy(() => import('views/application/users/list/Style2')));

// application - customer routing
const AppCustomerList = Loadable(lazy(() => import('views/application/customer/CustomerList')));
const AppCustomerOrderList = Loadable(lazy(() => import('views/application/customer/OrderList')));
const AppCustomerCreateInvoice = Loadable(lazy(() => import('views/application/customer/CreateInvoice')));
const AppCustomerOrderDetails = Loadable(lazy(() => import('views/application/customer/OrderDetails')));
const AppCustomerProduct = Loadable(lazy(() => import('views/application/customer/Product')));
const AppCustomerProductReview = Loadable(lazy(() => import('views/application/customer/ProductReview')));

// application routing
const AppChat = Loadable(lazy(() => import('views/application/chat')));
const AppKanban = Loadable(lazy(() => import('views/application/kanban')));
const AppKanbanBacklogs = Loadable(lazy(() => import('views/application/kanban/Backlogs')));
const AppKanbanBoard = Loadable(lazy(() => import('views/application/kanban/Board')));
const AppMail = Loadable(lazy(() => import('views/application/mail')));
const AppCalendar = Loadable(lazy(() => import('views/application/calendar')));
const AppContactCard = Loadable(lazy(() => import('views/application/contact/Card')));
const AppContactList = Loadable(lazy(() => import('views/application/contact/List')));

// application e-commerce pages
const AppECommProducts = Loadable(lazy(() => import('views/application/e-commerce/Products')));
const AppECommProductDetails = Loadable(lazy(() => import('views/application/e-commerce/ProductDetails')));
const AppECommProductList = Loadable(lazy(() => import('views/application/e-commerce/ProductList')));
const AppECommCheckout = Loadable(lazy(() => import('views/application/e-commerce/Checkout')));

// forms component routing
const FrmComponentsTextfield = Loadable(lazy(() => import('views/forms/components/TextField')));
const FrmComponentsButton = Loadable(lazy(() => import('views/forms/components/Button')));
const FrmComponentsCheckbox = Loadable(lazy(() => import('views/forms/components/Checkbox')));
const FrmComponentsRadio = Loadable(lazy(() => import('views/forms/components/Radio')));
const FrmComponentsSwitch = Loadable(lazy(() => import('views/forms/components/Switch')));
const FrmComponentsAutoComplete = Loadable(lazy(() => import('views/forms/components/AutoComplete')));
const FrmComponentsSlider = Loadable(lazy(() => import('views/forms/components/Slider')));
const FrmComponentsDateTime = Loadable(lazy(() => import('views/forms/components/DateTime')));

// forms plugins layout
const FrmLayoutLayout = Loadable(lazy(() => import('views/forms/layouts/Layouts')));
const FrmLayoutMultiColumnForms = Loadable(lazy(() => import('views/forms/layouts/MultiColumnForms')));
const FrmLayoutActionBar = Loadable(lazy(() => import('views/forms/layouts/ActionBar')));
const FrmLayoutStickyActionBar = Loadable(lazy(() => import('views/forms/layouts/StickyActionBar')));

// forms plugins routing
const FrmAutocomplete = Loadable(lazy(() => import('views/forms/plugins/AutoComplete')));
const FrmMask = Loadable(lazy(() => import('views/forms/plugins/Mask')));
const FrmClipboard = Loadable(lazy(() => import('views/forms/plugins/Clipboard')));
const FrmRecaptcha = Loadable(lazy(() => import('views/forms/plugins/Recaptcha')));
const FrmWysiwugEditor = Loadable(lazy(() => import('views/forms/plugins/WysiwugEditor')));
const FrmModal = Loadable(lazy(() => import('views/forms/plugins/Modal')));
const FrmTooltip = Loadable(lazy(() => import('views/forms/plugins/Tooltip')));

// table routing
const TableBasic = Loadable(lazy(() => import('views/forms/tables/TableBasic')));
const TableDense = Loadable(lazy(() => import('views/forms/tables/TableDense')));
const TableEnhanced = Loadable(lazy(() => import('views/forms/tables/TableEnhanced')));
const TableData = Loadable(lazy(() => import('views/forms/tables/TableData')));
const TableCustomized = Loadable(lazy(() => import('views/forms/tables/TablesCustomized')));
const TableStickyHead = Loadable(lazy(() => import('views/forms/tables/TableStickyHead')));
const TableCollapsible = Loadable(lazy(() => import('views/forms/tables/TableCollapsible')));

// forms validation
const FrmFormsValidation = Loadable(lazy(() => import('views/forms/forms-validation')));
const FrmFormsWizard = Loadable(lazy(() => import('views/forms/forms-wizard')));

// chart routing
const ChartApexchart = Loadable(lazy(() => import('views/forms/chart/Apexchart')));
const OrgChartPage = Loadable(lazy(() => import('views/forms/chart/OrgChart')));

// basic ui-elements routing
const BasicUIAccordion = Loadable(lazy(() => import('views/ui-elements/basic/UIAccordion')));
const BasicUIAvatar = Loadable(lazy(() => import('views/ui-elements/basic/UIAvatar')));
const BasicUIBadges = Loadable(lazy(() => import('views/ui-elements/basic/UIBadges')));
const BasicUIBreadcrumb = Loadable(lazy(() => import('views/ui-elements/basic/UIBreadcrumb')));
const BasicUICards = Loadable(lazy(() => import('views/ui-elements/basic/UICards')));
const BasicUIChip = Loadable(lazy(() => import('views/ui-elements/basic/UIChip')));
const BasicUIList = Loadable(lazy(() => import('views/ui-elements/basic/UIList')));
const BasicUITabs = Loadable(lazy(() => import('views/ui-elements/basic/UITabs')));

// advance ui-elements routing
const AdvanceUIAlert = Loadable(lazy(() => import('views/ui-elements/advance/UIAlert')));
const AdvanceUIDialog = Loadable(lazy(() => import('views/ui-elements/advance/UIDialog')));
const AdvanceUIPagination = Loadable(lazy(() => import('views/ui-elements/advance/UIPagination')));
const AdvanceUIProgress = Loadable(lazy(() => import('views/ui-elements/advance/UIProgress')));
const AdvanceUIRating = Loadable(lazy(() => import('views/ui-elements/advance/UIRating')));
const AdvanceUISnackbar = Loadable(lazy(() => import('views/ui-elements/advance/UISnackbar')));
const AdvanceUISkeleton = Loadable(lazy(() => import('views/ui-elements/advance/UISkeleton')));
const AdvanceUISpeeddial = Loadable(lazy(() => import('views/ui-elements/advance/UISpeeddial')));
const AdvanceUITimeline = Loadable(lazy(() => import('views/ui-elements/advance/UITimeline')));
const AdvanceUIToggleButton = Loadable(lazy(() => import('views/ui-elements/advance/UIToggleButton')));
const AdvanceUITreeview = Loadable(lazy(() => import('views/ui-elements/advance/UITreeview')));

// pricing page routing
const PagesPrice1 = Loadable(lazy(() => import('views/pages/pricing/Price1')));
const PagesPrice2 = Loadable(lazy(() => import('views/pages/pricing/Price2')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const UtilsAnimation = Loadable(lazy(() => import('views/utilities/Animation')));
const UtilsGrid = Loadable(lazy(() => import('views/utilities/Grid')));
// Institute routing
// const AppUserSocialProfile = Loadable(lazy(() => import('views/application/users/social-profile')));
const InstituteList = Loadable(lazy(() => import('views/application/institute/index')));
const InstituteAdd = Loadable(lazy(() => import('views/application/institute/Add')));
const InstituteEdit = Loadable(lazy(() => import('views/application/institute/Edit')));

const DashboardModule = Loadable(lazy(() => import('views/application/dashboard-module/index')));

// College
const CollegeList = Loadable(lazy(() => import('views/application/college/index')));

// Department
const DepartmentList = Loadable(lazy(() => import('views/application/department/index')));
// sample page routing/admission/entranceexam
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
// Courses
const SectionList = Loadable(lazy(() => import('views/application/section/index')));
// admission-custom

const AdmissionCustom = Loadable(lazy(() => import('views/application/admission-custom/index')));
const AddAcademy = Loadable(lazy(() => import('views/application/academicyearsetup/AddAcademy')));
const EditAcademy = Loadable(lazy(() => import('views/application/academicyearsetup/Edit')));
const AcademyYearSetup = Loadable(lazy(() => import('views/application/academicyearsetup/index')));
const Course = Loadable(lazy(() => import('views/application/course/index')));
const Subject = Loadable(lazy(() => import('views/application/subjects/index')));
const Quota = Loadable(lazy(() => import('views/application/quota/index')));
const Qualification = Loadable(lazy(() => import('views/application/qualification/index')));
const Fee = Loadable(lazy(() => import('views/application/fee/index')));
const FeeMaping = Loadable(lazy(() => import('views/application/feeMaping/index')));
const FeeMapingEdit = Loadable(lazy(() => import('views/application/feeMaping/Edit')));
const FeeFine = Loadable(lazy(() => import('views/application/feeFine/index')));
const AddAcountingDivision = Loadable(lazy(() => import('views/application/feeacounting/index')));
const EditAcountingDivesion = Loadable(lazy(() => import('views/application/feeacounting/EditAcountingDivision')));
const AddReceipt = Loadable(lazy(() => import('views/application/receiptbook/index')));
const EditReceipt = Loadable(lazy(() => import('views/application/receiptbook/EditReceipt')));
const InsititudeAcademic = Loadable(lazy(() => import('views/InsititudeAcademy/index')));
const InsititudeEdit = Loadable(lazy(() => import('views/InsititudeAcademy/EditInsititute')));
const InsitituteQualification = Loadable(lazy(() => import('views/application/qualification/index')));
const InsitituteDocument = Loadable(lazy(() => import('views/application/Document/index')));

const Period = Loadable(lazy(() => import('views/application/period/index')));
// src\views\InsititudeAcademy
//= =========================================================================//

// menus routing
const Menus = Loadable(lazy(() => import('views/application/menu/Menus')));

// Attendence
const StudentAttendance = Loadable(lazy(() => import('views/application/StudentAttendance/studentAttendance')));
const StudentAttendanceFromTeacher = Loadable(lazy(() => import('views/application/StudentAttendance/studentAttendanceFromTeacher')));
const StaffAttendance = Loadable(lazy(() => import('views/application/staffAttendance/staffAttendance')));
const WorkingDays = Loadable(lazy(() => import('views/application/workingdays/index')));
const Holidays = Loadable(lazy(() => import('views/application/holiday/index')));

const PayRoleMaster = Loadable(lazy(() => import('views/application/payrole/payrolemaster/Index')));
const PayRoleMasterEdit = Loadable(lazy(() => import('views/application/payrole/payrolemaster/EditMasterPayRole')));
const PayRoleAllocation = Loadable(lazy(() => import('views/application/payrole-allocation/Index')));
const GenerateBulkPaySlip = Loadable(lazy(() => import('views/application/payrole/generate-bulk-payslip/index')));
const PayslipSummary = Loadable(lazy(() => import('views/application/payrole/generate-bulk-payslip/PayrollSummry')));
const PaySlip = Loadable(lazy(() => import('views/application/payrole/generate-bulk-payslip/PaySlip')));

const LeaveMaster = Loadable(lazy(() => import('views/application/leave/leavemaster/index')));
const LeaveMasterEdit = Loadable(lazy(() => import('views/application/leave/leavemaster/EditLeaveMaster')));
const TestDataGrid = Loadable(lazy(() => import('views/application/test/index')));
const StaffRegistration = Loadable(lazy(() => import('views/application/staff/index')));
const StaffTimeTableDownlaod = Loadable(lazy(() => import('views/application/staff/timeTablePdf/index')));
const StaffEditRegistration = Loadable(lazy(() => import('views/application/staff/Review')));
const StaffBatchSubjectAllocation = Loadable(lazy(() => import('views/application/staff/subject-allocation/index')));
const ExternalStaffBatchSubjectAllocation = Loadable(
    lazy(() => import('views/application/staff/subject-allocation/external-subject-allocation'))
);
const FacultySubjectAllocation = Loadable(lazy(() => import('views/application/staff/facultysubjectallocation/FacultySubjectAllocatio')));
// const InstituteStaffRegistration = Loadable(lazy(() => import('views/application/staffOld/index')));
const TimeTable = Loadable(lazy(() => import('views/application/timetable/index')));
const TimeTableDashboard = Loadable(lazy(() => import('views/application/timetabledashboard/TimeTableDashBoard')));
const StaffCategory = Loadable(lazy(() => import('views/application/staffCategory/index')));
const GenerateTimeTable = Loadable(lazy(() => import('views/application/genrateTimeTable/index')));
const DownLoadTimeTable = Loadable(lazy(() => import('views/application/genrateTimeTable/downloadTimeTable/index')));
const TimeSlot = Loadable(lazy(() => import('views/application/timeslot/index')));
const TimeSlotGroupList = Loadable(lazy(() => import('views/application/timeslotgroup/index')));
const Session = Loadable(lazy(() => import('views/application/session/index')));

const BankMaster = Loadable(lazy(() => import('views/application/bank/index')));
const BranchListMaster = Loadable(lazy(() => import('views/application/bank/branch/index')));
const InstituteSetup = Loadable(lazy(() => import('views/application/institute/setup/index')));
const StudentDiscontinue = Loadable(lazy(() => import('views/application/studentdiscontinue/StudentDiscontinue')));
const StudentDiscontinueList = Loadable(
    lazy(() => import('views/application/studentdiscontinue/studentdiscontinue/AdmissionCancelStudentList'))
);
const MontlyBankStatement = Loadable(lazy(() => import('views/application/payrole/montly-bank-statement')));

const SubjectAllocationByBatch = Loadable(lazy(() => import('views/application/subjecct-allocation-by-batch/SubjectAllocationByBatch')));

const MainSettings = Loadable(lazy(() => import('views/application/institute/main_settings/Settings')));

const GradeList = Loadable(lazy(() => import('views/application/grade/index')));
const GradeEdit = Loadable(lazy(() => import('views/application/grade/EditGrade')));

const CalendarEvent = Loadable(lazy(() => import('views/application/calendar/index')));
const UpdateLOP = Loadable(lazy(() => import('views/application/leave/lop/UpdateLOP')));

// ==============================|| MAIN ROUTING ||============================== //

const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/institute/dashboard',
            element: <InstituteDashboard />
        },
        {
            path: '/exam/dashboard',
            element: <ExamDashboard />
        },
        {
            path: '/schoolwise/exam/dashboard/:courseType',
            element: <SchoolExamDashboard />
        },
        {
            path: '/coursewise/exam/dashboard/:courseType/:schoolId',
            element: <CourseExamDashboard />
        },
        {
            path: '/departmentwise/exam/dashboard/:courseType/:departmentId',
            element: <DepartmentExamDashboard />
        },
        {
            path: '/institute/academic/year',
            element: <InsititudeAcademic />
        },
        {
            path: '/institute/edit/:id/:admissionsOpen',
            element: <InsititudeEdit />
        },
        {
            path: '/institute/qualification',
            element: <InsitituteQualification />
        },
        {
            path: '/institute/document',
            element: <InsitituteDocument />
        },
        {
            path: '/widget/statistics',
            element: <WidgetStatistics />
        },
        {
            path: '/widget/data',
            element: <WidgetData />
        },
        {
            path: '/widget/chart',
            element: <WidgetChart />
        },

        {
            path: '/user/social-profile/:tab',
            element: <AppUserSocialProfile />
        },
        {
            path: '/user/account-profile/profile1',
            element: <AppUserAccountProfile1 />
        },
        {
            path: '/user/account-profile/profile2',
            element: <AppUserAccountProfile2 />
        },
        {
            path: '/user/account-profile/profile3',
            element: <AppUserAccountProfile3 />
        },
        {
            path: '/change/profile/picture',
            element: <ChangeProfilePicture />
        },

        {
            path: '/user/card/card1',
            element: <AppProfileCardStyle1 />
        },
        {
            path: '/user/card/card2',
            element: <AppProfileCardStyle2 />
        },
        {
            path: '/user/card/card3',
            element: <AppProfileCardStyle3 />
        },
        {
            path: '/user/list/list1',
            element: <AppProfileListStyle1 />
        },
        {
            path: '/user/list/list2',
            element: <AppProfileListStyle2 />
        },
        {
            path: '/customer/customer-list',
            element: <AppCustomerList />
        },
        {
            path: '/customer/order-list',
            element: <AppCustomerOrderList />
        },
        {
            path: '/customer/create-invoice',
            element: <AppCustomerCreateInvoice />
        },
        {
            path: '/customer/order-details',
            element: <AppCustomerOrderDetails />
        },
        {
            path: '/customer/product',
            element: <AppCustomerProduct />
        },
        {
            path: '/customer/product-review',
            element: <AppCustomerProductReview />
        },

        {
            path: '/app/chat',
            element: <AppChat />
        },
        {
            path: '/app/mail',
            element: <AppMail />
        },
        {
            path: '/app/kanban',
            element: <AppKanban />,
            children: [
                {
                    path: 'backlogs',
                    element: <AppKanbanBacklogs />
                },
                {
                    path: 'board',
                    element: <AppKanbanBoard />
                }
            ]
        },
        {
            path: '/app/calendar',
            element: <AppCalendar />
        },
        {
            path: '/app/contact/c-card',
            element: <AppContactCard />
        },
        {
            path: '/app/contact/c-list',
            element: <AppContactList />
        },

        {
            path: '/e-commerce/products',
            element: <AppECommProducts />
        },
        {
            path: '/e-commerce/product-details/:id',
            element: <AppECommProductDetails />
        },
        {
            path: '/e-commerce/product-list',
            element: <AppECommProductList />
        },
        {
            path: '/e-commerce/checkout',
            element: <AppECommCheckout />
        },
        {
            path: '/components/text-field',
            element: <FrmComponentsTextfield />
        },
        {
            path: '/components/button',
            element: <FrmComponentsButton />
        },
        {
            path: '/components/checkbox',
            element: <FrmComponentsCheckbox />
        },
        {
            path: '/components/radio',
            element: <FrmComponentsRadio />
        },
        {
            path: '/components/autocomplete',
            element: <FrmComponentsAutoComplete />
        },
        {
            path: '/components/slider',
            element: <FrmComponentsSlider />
        },
        {
            path: '/components/switch',
            element: <FrmComponentsSwitch />
        },
        {
            path: '/components/date-time',
            element: <FrmComponentsDateTime />
        },

        {
            path: '/forms/layouts/layouts',
            element: <FrmLayoutLayout />
        },
        {
            path: '/forms/layouts/multi-column-forms',
            element: <FrmLayoutMultiColumnForms />
        },
        {
            path: '/forms/layouts/action-bar',
            element: <FrmLayoutActionBar />
        },
        {
            path: '/forms/layouts/sticky-action-bar',
            element: <FrmLayoutStickyActionBar />
        },

        {
            path: '/forms/frm-autocomplete',
            element: <FrmAutocomplete />
        },
        {
            path: '/forms/frm-mask',
            element: <FrmMask />
        },
        {
            path: '/forms/frm-clipboard',
            element: <FrmClipboard />
        },
        {
            path: '/forms/frm-recaptcha',
            element: <FrmRecaptcha />
        },
        {
            path: '/forms/frm-wysiwug',
            element: <FrmWysiwugEditor />
        },
        {
            path: '/forms/frm-modal',
            element: <FrmModal />
        },
        {
            path: '/forms/frm-tooltip',
            element: <FrmTooltip />
        },

        {
            path: '/tables/tbl-basic',
            element: <TableBasic />
        },
        {
            path: '/tables/tbl-dense',
            element: <TableDense />
        },
        {
            path: '/tables/tbl-enhanced',
            element: <TableEnhanced />
        },
        {
            path: '/tables/tbl-data',
            element: <TableData />
        },
        {
            path: '/tables/tbl-customized',
            element: <TableCustomized />
        },
        {
            path: '/tables/tbl-sticky-header',
            element: <TableStickyHead />
        },
        {
            path: '/tables/tbl-collapse',
            element: <TableCollapsible />
        },

        {
            path: 'forms/charts/apexchart',
            element: <ChartApexchart />
        },
        {
            path: '/forms/charts/orgchart',
            element: <OrgChartPage />
        },
        {
            path: '/forms/forms-validation',
            element: <FrmFormsValidation />
        },
        {
            path: '/forms/forms-wizard',
            element: <FrmFormsWizard />
        },

        {
            path: '/basic/accordion',
            element: <BasicUIAccordion />
        },
        {
            path: '/basic/avatar',
            element: <BasicUIAvatar />
        },
        {
            path: '/basic/badges',
            element: <BasicUIBadges />
        },
        {
            path: '/basic/breadcrumb',
            element: <BasicUIBreadcrumb />
        },
        {
            path: '/basic/cards',
            element: <BasicUICards />
        },
        {
            path: '/basic/chip',
            element: <BasicUIChip />
        },
        {
            path: '/basic/list',
            element: <BasicUIList />
        },
        {
            path: '/basic/tabs',
            element: <BasicUITabs />
        },

        {
            path: '/advance/alert',
            element: <AdvanceUIAlert />
        },
        {
            path: '/advance/dialog',
            element: <AdvanceUIDialog />
        },
        {
            path: '/advance/pagination',
            element: <AdvanceUIPagination />
        },
        {
            path: '/advance/progress',
            element: <AdvanceUIProgress />
        },
        {
            path: '/advance/rating',
            element: <AdvanceUIRating />
        },
        {
            path: '/advance/snackbar',
            element: <AdvanceUISnackbar />
        },
        {
            path: '/advance/skeleton',
            element: <AdvanceUISkeleton />
        },
        {
            path: '/advance/speeddial',
            element: <AdvanceUISpeeddial />
        },
        {
            path: '/advance/timeline',
            element: <AdvanceUITimeline />
        },
        {
            path: '/advance/toggle-button',
            element: <AdvanceUIToggleButton />
        },
        {
            path: '/advance/treeview',
            element: <AdvanceUITreeview />
        },

        {
            path: '/pages/price/price1',
            element: <PagesPrice1 />
        },
        {
            path: '/pages/price/price2',
            element: <PagesPrice2 />
        },

        {
            path: '/utils/util-typography',
            element: <UtilsTypography />
        },
        {
            path: '/utils/util-color',
            element: <UtilsColor />
        },
        {
            path: '/utils/util-shadow',
            element: <UtilsShadow />
        },
        {
            path: '/icons/tabler-icons',
            element: <UtilsTablerIcons />
        },
        {
            path: '/utils/util-animation',
            element: <UtilsAnimation />
        },
        {
            path: '/utils/util-grid',
            element: <UtilsGrid />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/analytics',
            element: <DashboardAnalytics />
        },
        {
            path: '/institute/list',
            element: <InstituteList />
        },
        {
            path: '/dashboard/module',
            element: <DashboardModule />
        },
        {
            path: '/institute/add',
            element: <InstituteAdd />
        },
        {
            path: '/institute/edit',
            element: <InstituteEdit />
        },
        {
            path: '/college/list',
            element: <CollegeList />
        },
        {
            path: '/department/list',
            element: <DepartmentList />
        },
        {
            path: '/section/list',
            element: <SectionList />
        },
        {
            path: 'custom/admission',
            element: <AdmissionCustom />
        },
        {
            path: '/academy/add',
            element: <AddAcademy />
        },
        {
            path: '/academy-year/setup',
            element: <AcademyYearSetup />
        },
        {
            path: '/course/list',
            element: <Course />
        },
        {
            path: '/menus/list',
            element: <Menus />
        },
        {
            path: '/menus/list/:id',
            element: <Menus />
        },
        {
            path: '/subject/:id/:sem',
            element: <Subject />
        },
        {
            path: '/quota',
            element: <Quota />
        },
        {
            path: '/qualification',
            element: <Qualification />
        },
        {
            path: '/fee',
            element: <Fee />
        },
        {
            path: '/feeMapping',
            element: <FeeMaping />
        },
        {
            path: '/feeMaping/edit/:id',
            element: <FeeMapingEdit />
        },
        {
            path: '/feeFine',
            element: <FeeFine />
        },
        {
            path: '/acounting/add',
            element: <AddAcountingDivision />
        },
        {
            path: '/acounting/edit/:id',
            element: <EditAcountingDivesion />
        },
        {
            path: '/receipt/add',
            element: <AddReceipt />
        },
        {
            path: '/receipt/edit/:id',
            element: <EditReceipt />
        },
        {
            path: '/academy/edit/:id',
            element: <EditAcademy />
        },
        {
            path: 'college/dashboard',
            element: <CollegeDashboard />
        },
        {
            path: '/student/attendance',
            element: <StudentAttendance />
        },
        {
            path: '/student/attendance/:timeSlotId/:timeTableId/:batchId/:sessionId/:subjectId/:courseDetailId',
            element: <StudentAttendanceFromTeacher />
        },
        {
            path: '/staff/attendance',
            element: <StaffAttendance />
        },
        {
            path: '/working/days',
            element: <WorkingDays />
        },
        {
            path: '/holiday',
            element: <Holidays />
        },
        {
            path: '/payrole/master',
            element: <PayRoleMaster />
        },
        {
            path: '/payrole/master/edit/:id',
            element: <PayRoleMasterEdit />
        },
        {
            path: '/payrole-allocation',
            element: <PayRoleAllocation />
        },
        {
            path: '/generate/bulk/pay/slip',
            element: <GenerateBulkPaySlip />
        },
        {
            path: '/payslip/summary',
            element: <PayslipSummary />
        },
        {
            path: '/pay/slip',
            element: <PaySlip />
        },
        {
            path: '/leave/master',
            element: <LeaveMaster />
        },
        {
            path: '/leave/master/edit/:id',
            element: <LeaveMasterEdit />
        },
        {
            path: '/test/datagrid',
            element: <TestDataGrid />
        },
        {
            path: '/staff/registration',
            element: <StaffRegistration />
        },
        {
            path: '/staff/timetable/download',
            element: <StaffTimeTableDownlaod />
        },
        {
            path: '/staff/registration/edit/:id',
            element: <StaffEditRegistration />
        },
        // {
        //     path: '/institute/staff/registration/:id',
        //     element: <InstituteStaffRegistration />
        // },
        {
            path: '/period',
            element: <Period />
        },
        {
            path: '/timetable',
            element: <TimeTable />
        },
        {
            path: '/timetable/dashboard',
            element: <TimeTableDashboard />
        },
        {
            path: '/staffCategory',
            element: <StaffCategory />
        },
        {
            path: '/generateTimeTable',
            element: <GenerateTimeTable />
        },
        {
            path: '/download/timetable',
            element: <DownLoadTimeTable />
        },
        {
            path: '/timeSlot/:id',
            element: <TimeSlot />
        },
        {
            path: '/time/slot/group/list',
            element: <TimeSlotGroupList />
        },
        {
            path: '/session',
            element: <Session />
        },
        {
            path: '/student/count/by/program',
            element: <StudentCountBuyCourseType />
        },
        {
            path: '/staff/batch/subject/allocation',
            element: <StaffBatchSubjectAllocation />
        },
        {
            path: '/external/staff/batch/subject/allocation',
            element: <ExternalStaffBatchSubjectAllocation />
        },
        {
            path: '/faculty/subject/allocation',
            element: <FacultySubjectAllocation />
        },
        {
            path: '/student/count/by/coursetype/:courseType',
            element: <TotalStudentByCourseType />
        },
        {
            path: '/bank/list',
            element: <BankMaster />
        },
        {
            path: '/branch/list',
            element: <BranchListMaster />
        },
        {
            path: '/institute/setup',
            element: <InstituteSetup />
        },
        {
            path: '/dicontinue/student',
            element: <StudentDiscontinue />
        },
        {
            path: '/dicontinue/student/list',
            element: <StudentDiscontinueList />
        },
        {
            path: '/monthly/bank/statement',
            element: <MontlyBankStatement />
        },
        {
            path: '/subject/allocation/by/batch',
            element: <SubjectAllocationByBatch />
        },
        {
            path: '/settings/:instituteId',
            element: <MainSettings />
        },
        {
            path: '/grade/list',
            element: <GradeList />
        },
        {
            path: '/grade/edit/:id',
            element: <GradeEdit />
        },
        {
            path: '/calender/event',
            element: <CalendarEvent />
        },
        {
            path: '/update/lop',
            element: <UpdateLOP />
        }
    ]
};

export default MainRoutes;
