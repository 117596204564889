import { menus } from './menus';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    // items: [dashboard, menus, widget, application, forms, elements, pages, utilities, support, other]
    items: [menus]
};

export default menuItems;
