/* eslint-disable no-case-declarations */
import { Divider, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import axios from 'axios';
import useMessageDispatcher from 'hooks/useMessageDispatcher';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    imageContainer: {
        textAlign: 'center',
        marginTop: '16px'
    },
    image: {
        maxWidth: '100%',
        maxHeight: '400px',
        margin: 'auto',
        display: 'block'
    },
    pdfContainer: {
        marginTop: '20px',
        marginBottom: '20px',
        maxWidth: '80%',
        height: '600px',
        marginLeft: '10%',
        marginRight: '10%',
        border: 'none'
    },
    topLevelPaper: {
        background: '#f5f5f5',
        margin: '20px',
        padding: '20px'
    },
    secondLevelPaper: {
        background: '#e0f2f1',
        margin: '10px',
        padding: '10px'
    },
    thirdLevelPaper: {
        background: '#f0f4c3',
        margin: '5px',
        padding: '5px'
    },
    textContent: {
        marginLeft: '20px',
        marginTop: '10px'
    }
}));

const TeachingResources = () => {
    const [lessonPlans, setLessonPlans] = useState([]);
    const location = useLocation();
    console.log(location.state, 'location');

    const showMessage = useMessageDispatcher();
    const classes = useStyles();

    const { state } = location;
    console.log(state, 'statestate');
    const { lessonPlanModules, subjectId, sessionId, id } = state.data;
    console.log(lessonPlanModules, 'lessonPlanModules62');
    const lessonPlanId = id;

    const renderResourceContent = (content) => {
        switch (content.resourceType) {
            case 'TEXT':
                return (
                    <Typography sx={{ textAlign: 'justify' }} className={classes.textContent}>
                        {content.resourceContent}
                    </Typography>
                );
            case 'IMAGE':
                return (
                    <Box className={classes.imageContainer}>
                        <img src={content.resourceContent} alt={content.resourceCaption || 'Resource Image'} className={classes.image} />
                        {content.resourceCaption && <Typography variant="caption">{content.resourceCaption}</Typography>}
                    </Box>
                );
            case 'PDF':
                return (
                    <embed
                        src={`${content.resourceContent}#view=FitH`}
                        type="application/pdf"
                        width="80%"
                        height="100%"
                        className={classes.pdfContainer}
                    />
                );
            case 'LIST':
                const listItems = JSON.parse(content.resourceContent);
                return (
                    <ul>
                        {listItems.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                );
            case 'LINK':
                return (
                    <Link to={content.resourceCaption} target="_blank" className={classes.textContent}>
                        {content.resourceContent || 'Link'}
                    </Link>
                );
            default:
                return null;
        }
    };

    const renderResource = (resource) => (
        <div key={resource.id}>
            <Typography variant="subtitle1" className={classes.textContent}>
                {resource.resourceTitle}
            </Typography>
            {resource.resourceContents?.map((content, index) => (
                <div key={index}>{renderResourceContent(content)}</div>
            ))}
        </div>
    );

    const getPaperStyle = (level) => {
        switch (level) {
            case 0:
                return classes.topLevelPaper;
            case 1:
                return classes.secondLevelPaper;
            case 2:
                return classes.thirdLevelPaper;
            default:
                return '';
        }
    };

    const renderModules = (modules, level = 0) => {
        const title = ['M', 'CH', 'TP'];
        console.log('Rendering modules at level:', level, 'Modules:', modules);
        return modules.map((mod) => (
            <Paper key={mod.id} elevation={3} className={getPaperStyle(level)}>
                <Typography variant="h5">{`${title[level]}:${mod.module}`}</Typography>
                {mod.resources && <Box sx={{ marginLeft: 1, marginRight: 1 }}>{mod.resources.map(renderResource)}</Box>}
                {mod.subModules && renderModules(mod.subModules, level + 1)}
            </Paper>
        ));
    };

    const injectResourcesIntoModulesRecursively = (modules, resources) =>
        modules.map((mod) => ({
            ...mod,
            resources: resources.filter((res) => res.moduleId === mod.moduleId),
            subModules: mod.subModules ? injectResourcesIntoModulesRecursively(mod.subModules, resources) : []
        }));

    const fetchResourcesByLessonPlanId = async () => {
        const params = { lessonPlanId };
        try {
            const response = await axios.get(`v1/subjects/lesson-plans/module/resources/get`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                },
                params
            });
            if (response.status === 200) {
                if (response.data.code !== 403) {
                    const { resources } = response.data;
                    const sortedResources = resources
                        .map((res) => ({
                            ...res,
                            resourceContents: res.resourceContents.sort((a, b) => a.seq - b.seq)
                        }))
                        .sort((a, b) => a.seq - b.seq);
                    const modifiedLessonPlan = injectResourcesIntoModulesRecursively(lessonPlanModules, sortedResources);

                    setLessonPlans(modifiedLessonPlan);
                    console.log(modifiedLessonPlan, 'modifiedLessonPlan');
                } else {
                    showMessage({ message: response.message, color: 'error' });
                }
            }
        } catch (error) {
            if (!error.response) {
                showMessage({ message: error.message, color: 'error' });
            } else {
                showMessage({ message: error.response.data.Msg, color: 'error' });
            }
        }
    };

    useEffect(() => {
        fetchResourcesByLessonPlanId();
    }, [location]);

    return (
        <div>
            {lessonPlans?.map((lessonPlan) => (
                <div key={lessonPlan.id}>{renderModules([lessonPlan])}</div>
            ))}
        </div>
    );
};

export default TeachingResources;
