/* eslint-disable prettier/prettier */
/* eslint-disable import/extensions */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// Department
const BatchList = Loadable(lazy(() => import('views/application/Batch/index')));
const AcademicSessionList = Loadable(lazy(() => import('views/application/academicsession/index')));
const FeeFlowPage = Loadable(lazy(() => import('views/application/academicsession/FeesFlow')))
const SubjectList = Loadable(lazy(() => import('views/MasterSetUp/Subject/main')));
const FinancialYearList =Loadable(lazy(()=>import('views/financialYear/index')));
const FeeFlowList = Loadable(lazy(() => import('views/application/academicsession/AcademicFeeFlowList')));
const FeeFlowDashboardReport = Loadable(lazy(() => import('views/application/academicsession/fee_flow_dashboard/FeeFlowDashBoard')));
const FeeFlowEdit = Loadable(lazy(() => import('views/application/academicsession/AcademicFeeFlowEdit')));
const LeaveApprovalFlow = Loadable(lazy(() => import('views/application/leaveapprovalflow/index')));
const EditLeaveApprovalFlow = Loadable(lazy(() => import('views/application/leaveapprovalflow/EditLeaveApprovalFlow')));
const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const MasterRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/batch/list',
            element: <BatchList />
        },
        {
            path: '/academicsession/list',
            element: <AcademicSessionList />
        },
        {
            path: '/fee/flow/:id',
            element: <FeeFlowPage />
        },
        {
            path: '/fee/flow/report',
            element: <FeeFlowDashboardReport />
        },
        {
            path: '/fee/flow/list/:academicSessionId',
            element: <FeeFlowList />
        },
        {
            path: '/fee/flow/edit/:id/:odd',
            element: <FeeFlowEdit />
        },
        {
            path: '/institute/financial/year',
            element: <FinancialYearList/>
        },
        {
            path: '/subject/list',
            element: <SubjectList />
        },
        {
            path: '/leave/approval/flow',
            element: <LeaveApprovalFlow />
        },
        {
            path: '/edit/leave/approval/flow/:id',
            element: <EditLeaveApprovalFlow />
        }
    ]
};

export default MasterRoutes;
