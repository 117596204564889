/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Modal, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import TimerIcon from '@mui/icons-material/Timer';
import { Box } from '@mui/system';

const timerContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px', // Adjust as needed
    backgroundColor: '#005e9f', // Adjust as needed
    color: 'white', // Adjust as needed
    borderRadius: '8px' // Adjust as needed
};

const timerTextStyle = {
    fontSize: '1rem',
    fontWeight: 'bold'
};

const Timer = ({ durationInSeconds, handleFinalSubmition }) => {
    console.log(durationInSeconds, 'durationInSeconds');
    const [timeRemaining, setTimeRemaining] = useState(durationInSeconds);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        let interval;

        if (timeRemaining > 0) {
            interval = setInterval(() => {
                setTimeRemaining((prevTime) => prevTime - 1);
            }, 1000);
        } else {
            setIsModalOpen(true);
            handleFinalSubmition();
        }
        return () => clearInterval(interval);
    }, [timeRemaining]);

    const handleCloseModal = () => {
        setIsModalOpen(false);
        navigate('/');
        // if (document.fullscreenElement) {
        //     if (document.exitFullscreen) {
        //         document.exitFullscreen();
        //     } else if (document.mozCancelFullScreen) {
        //         document.mozCancelFullScreen();
        //     } else if (document.webkitExitFullscreen) {
        //         document.webkitExitFullscreen();
        //     } else if (document.msExitFullscreen) {
        //         document.msExitFullscreen();
        //     }
        // }
    };
    // Calculate hours, minutes, and seconds
    const hours = Math.floor(timeRemaining / 3600);
    const minutes = Math.floor((timeRemaining % 3600) / 60);
    const seconds = timeRemaining % 60;

    return (
        <>
            <div style={timerContainerStyle}>
                <TimerIcon />
                <Typography variant="h4" color="white" style={timerTextStyle}>
                    {`${hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
                </Typography>
            </div>
            <Dialog open={isModalOpen} onClose={handleCloseModal}>
                <DialogTitle>Your Exam Has Been Stopped</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Unfortunately, your exam has been stopped either due to time expiration or other reasons.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary" variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
const TimerComponent = ({ handleFinalSubmition, duration }) => {
    console.log(duration, 'duration');
    console.log(handleFinalSubmition, 'handleFinalSubmition');

    console.log(typeof duration); // Check the type of duration

    const timeParts = duration.split(':');
    console.log(timeParts, 'duration');

    // Convert duration to seconds
    const durationInSeconds = duration
        .split(':')
        .map(Number)
        .reduce((acc, time, index) => acc + time * 60 ** (2 - index), 0);

    console.log(durationInSeconds, 'durationInSeconds');

    return <Timer durationInSeconds={durationInSeconds} handleFinalSubmition={handleFinalSubmition} />;
};

export default TimerComponent;
