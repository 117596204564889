import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// eslint-disable-next-line import/no-unresolved
const LibrarianDashboard = Loadable(lazy(() => import('views/Library/dashboards/librarian')));
const LibrarySetup = Loadable(lazy(() => import('views/Library/library-setup')));
const AddBooks = Loadable(lazy(() => import('views/Library/library-setup/AddBooks')));
const ShelfSetup = Loadable(lazy(() => import('views/Library/shelf-setup')));
const CategorySetup = Loadable(lazy(() => import('views/Library/book-categories')));
const GenreSetup = Loadable(lazy(() => import('views/Library/genre')));
const BookSetup = Loadable(lazy(() => import('views/Library/books')));
const AssignedBooks = Loadable(lazy(() => import('views/Library/dashboards/reports/book-reports/AssignedBooks')));
const IssuedBooks = Loadable(lazy(() => import('views/Library/dashboards/reports/book-reports/IssuedBooks')));
const DigitalRequests = Loadable(lazy(() => import('views/Library/dashboards/reports/book-reports/DigitalRequests')));
const IssueReturnSetup = Loadable(lazy(() => import('views/Library/issue-return')));
const LibrarySettings = Loadable(lazy(() => import('views/Library/Settings')));
const LibraryAdmin = Loadable(lazy(() => import('views/Library/admin-setup')));
const StudentProfile = Loadable(lazy(() => import('views/Library/issue-return/student/Profile')));
const StaffProfile = Loadable(lazy(() => import('views/Library/issue-return/staff/Profile')));
const Visitors = Loadable(lazy(() => import('views/Library/dashboards/reports/Visitors')));
const LibraryDashboard = Loadable(lazy(() => import('views/Library/LibraryDashboard/LibraryDashboard')));
const LibraryCategoryType = Loadable(lazy(() => import('views/Library/LibraryCategoryType/index')));
const LibraryCategory = Loadable(lazy(() => import('views/Library/LibraryCategory/index')));
const LibrarySetting = Loadable(lazy(() => import('views/Library/Seeting/index')));
const LibraryIssue = Loadable(lazy(() => import('views/Library/IssuBook/index')));
const BookHistory = Loadable(lazy(() => import('views/Library/IssuBook/HistoryBook')));
// const LibraryIssue = Loadable(lazy(() => import('views/Library/Issu')));
const LibraryAddBooks = Loadable(lazy(() => import('views/Library/AddBook/index')));
const EditCategoryType = Loadable(lazy(() => import('views/Library/LibraryCategoryType/EditCategoryType')));

const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const LibraryRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/library/dashboard/admin',
            element: <LibrarianDashboard />
        },
        {
            path: '/library/setup',
            element: <LibrarySetup />
        },
        {
            path: '/library/add-books',
            element: <AddBooks />
        },
        {
            path: '/library/shelves',
            element: <ShelfSetup />
        },
        {
            path: '/library/genre',
            element: <GenreSetup />
        },
        {
            path: '/library/books',
            element: <BookSetup />
        },
        {
            path: '/library/assigned-books',
            element: <AssignedBooks />
        },
        {
            path: '/library/issued-books',
            element: <IssuedBooks />
        },
        {
            path: '/library/digial/requests',
            element: <DigitalRequests />
        },
        {
            path: '/library/assign-admin',
            element: <LibraryAdmin />
        },
        {
            path: '/library/category',
            element: <CategorySetup />
        },
        {
            path: '/library/books/issue-return',
            element: <IssueReturnSetup />
        },
        {
            path: '/library/settings/:id',
            element: <LibrarySettings />
        },
        {
            path: '/library/student/profile/:id',
            element: <StudentProfile />
        },
        {
            path: '/library/staff/profile',
            element: <StaffProfile />
        },
        {
            path: '/library/visitors',
            element: <Visitors />
        }
        // {
        //     path: '/library/dashboard',
        //     element: <LibraryDashboard />
        // },
        // {
        //     path: '/library/category',
        //     element: <LibraryCategory />
        // },
        // {
        //     path: '/library/category-type',
        //     element: <LibraryCategoryType />
        // },
        // {
        //     path: '/library/books',
        //     element: <LibraryAddBooks />
        // },
        // {
        //     path: '/library/books/issue-return',
        //     element: <LibraryIssue />
        // },
        // {
        //     path: '/library/settings',
        //     element: <LibrarySetting />
        // },
        // {
        //     path: '/library/Issue',
        //     element: <LibraryIssue />
        // },
        // {
        //     path: '/library/history',
        //     element: <BookHistory />
        // },
        // {
        //     path: '/library/editCategoryType/id',
        //     element: <LibraryAddBooks />
        // }
    ]
};
export default LibraryRoutes;
