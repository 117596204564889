import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const ScholarshipSetup = Loadable(lazy(() => import('views/scholarship-setup')));
const ScholarshipCriteriaSetup = Loadable(lazy(() => import('views/scholarship-setup/criteria-and-documents')));
const AppliedScholarshipList = Loadable(lazy(() => import('views/scholarship-setup/appliedscholarshiplist/AppliedList')));
const roles = ['ROLE_STUDENT', 'ROLE_STUDENT_ADMISSION', 'ROLE_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN', 'ROLE_ADMIN'];

const scholarshipRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/scholarship/setup',
            element: <ScholarshipSetup />
        },
        {
            path: '/scholarship/criteria/:id',
            element: <ScholarshipCriteriaSetup />
        },
        {
            path: '/applied/scholarship/list',
            element: <AppliedScholarshipList />
        }
    ]
};
export default scholarshipRoutes;
