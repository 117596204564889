import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const SportsList = Loadable(lazy(() => import('views/application/sports/master/sports/index')));
const StudentSportsMapping = Loadable(lazy(() => import('views/application/sports/studentsportsmapping/StudentSportsMapping')));
const CoachSportsMapping = Loadable(lazy(() => import('views/application/sports/staffsportsmapping/StaffSportsMapping')));
const ClubTypeMaster = Loadable(lazy(() => import('views/application/sports/master/clubtype/index')));
const ClubList = Loadable(lazy(() => import('views/application/sports/master/club/index')));
const ClubCoachMapping = Loadable(lazy(() => import('views/application/sports/clubcoachmapping/CoachClubMapping')));
const ClubProfile = Loadable(lazy(() => import('views/application/sports/ClubProfile')));
const ClubStudentMapping = Loadable(lazy(() => import('views/application/sports/clubstudentmapping/ClubStudentMapping')));
const ClubProfilePage = Loadable(lazy(() => import('views/application/sports/clubtabs/Profile')));
const SportsSessionMapping = Loadable(lazy(() => import('views/application/sports/master/sessionsportsmapping')));
const PlayersList = Loadable(lazy(() => import('views/application/sports/coachdashboard/PlayerList')));
const GroundList = Loadable(lazy(() => import('views/application/sports/master/ground/index')));
const TournamentSetup = Loadable(lazy(() => import('views/application/sports/master/tournamentsetup/index')));
const TournamentEdit = Loadable(lazy(() => import('views/application/sports/master/tournamentsetup/EditTournament')));
const RoundSetup = Loadable(lazy(() => import('views/application/sports/master/roundssetup/SportsListByTournament')));
const Rounds = Loadable(lazy(() => import('views/application/sports/master/roundssetup/index')));
const AddPlayers = Loadable(lazy(() => import('views/application/sports/coachdashboard/PlayersTab')));
const TournamnetTeamMapping = Loadable(lazy(() => import('views/application/sports/tournamentteammapping/TournamentTeamMapping')));
const TournamentTeamSetup = Loadable(lazy(() => import('views/application/sports/admintournamentteamsetup/TeamSetup')));
const TournamentTeamMemberSetup = Loadable(lazy(() => import('views/application/sports/admintournamentteamsetup/teammembersetup/index')));
const TournamentSchedule = Loadable(lazy(() => import('views/application/sports/tournamentschedule/index')));
const Schedule = Loadable(lazy(() => import('views/application/sports/schedule/Schedule')));

const roles = ['ROLE_STUDENT', 'ROLE_STUDENT_ADMISSION', 'ROLE_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN', 'ROLE_ADMIN'];

const SportsRouts = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/sports/list',
            element: <SportsList />
        },
        {
            path: '/student/sports/mapping',
            element: <StudentSportsMapping />
        },
        {
            path: '/coaach/sports/mapping',
            element: <CoachSportsMapping />
        },
        {
            path: '/club/type',
            element: <ClubTypeMaster />
        },
        {
            path: '/club/list',
            element: <ClubList />
        },
        {
            path: '/club/coach/mapping',
            element: <ClubCoachMapping />
        },
        {
            path: '/club/profile/:id',
            element: <ClubProfile />
        },
        {
            path: '/club/student/mapping',
            element: <ClubStudentMapping />
        },
        {
            path: '/club/profile',
            element: <ClubProfilePage />
        },
        {
            path: '/sport/session/mapping',
            element: <SportsSessionMapping />
        },
        {
            path: '/players/list/:sportId',
            element: <PlayersList />
        },
        {
            path: '/grounds/list',
            element: <GroundList />
        },
        {
            path: '/tournament/setup',
            element: <TournamentSetup />
        },
        {
            path: '/tournament/edit/:id',
            element: <TournamentEdit />
        },
        {
            path: '/round/setup',
            element: <RoundSetup />
        },
        {
            path: '/add/round/by/:sId/:tId',
            element: <Rounds />
        },
        {
            path: '/add/players/:teamId/:tId/:sId',
            element: <AddPlayers />
        },
        {
            path: '/tournament/team/mapping/:tId',
            element: <TournamnetTeamMapping />
        },
        {
            path: '/tournament/team/setup',
            element: <TournamentTeamSetup />
        },
        {
            path: '/tournament/team/mamber/setup/:teamId/:tournamentId',
            element: <TournamentTeamMemberSetup />
        },
        {
            path: '/tournament/schedule/setup',
            element: <TournamentSchedule />
        },
        {
            path: '/schedule',
            element: <Schedule />
        }
    ]
};
export default SportsRouts;
