/* eslint-disable eqeqeq */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unreachable */
// QuestionPreview.js

import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';

const QuestionPreview = ({ questionList, handleFinalSubmition, attemptedQuestions }) => {
    const getColor = (questionIndex) => {
        const isAttempted = attemptedQuestions?.hasOwnProperty(questionIndex);

        if (isAttempted) {
            const currentAttemptedQuestion = attemptedQuestions[questionIndex];

            if (currentAttemptedQuestion?.questionType === 'MULTIPLE_CHOICE' && currentAttemptedQuestion?.selectMcqAnswer === null) {
                return 'yellow';
            }
            if (currentAttemptedQuestion?.questionType === 'DESCRIPTIVE' && currentAttemptedQuestion?.descriptiveAnswers === '') {
                return 'yellow';
            }
            if (
                currentAttemptedQuestion?.questionType === 'MULTI_ANSWER' &&
                (currentAttemptedQuestion?.selectedOptions[questionIndex]?.length === 0 ||
                    currentAttemptedQuestion?.selectedOptions[questionIndex]?.length === undefined)
            ) {
                return 'yellow';
            }
            return 'green';
        }
        return 'gray';
    };

    return (
        <div style={{ minHeight: '50vh' }}>
            {Array.from({ length: Math.ceil(questionList.length / 3) }).map((_, rowIndex) => (
                <div key={rowIndex} style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                    {questionList.slice(rowIndex * 3, (rowIndex + 1) * 3).map((_, colIndex) => {
                        const questionIndex = rowIndex * 3 + colIndex;

                        return (
                            <Box
                                key={colIndex}
                                style={{
                                    backgroundColor: getColor(questionIndex),
                                    padding: '10px',
                                    marginBottom: '10px',
                                    flex: 1,
                                    borderRadius: '10px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '50px',
                                    width: '50px'
                                }}
                            >
                                <Typography variant="h2"> {questionIndex + 1}</Typography>
                            </Box>
                        );
                    })}
                </div>
            ))}
            <Grid container spacing={2} alignItems="center" sx={{ m: 1 }}>
                <Grid item>
                    <Box display="flex" alignItems="center">
                        <div
                            style={{
                                backgroundColor: 'green',
                                height: '20px',
                                width: '20px',
                                marginRight: '5px',
                                borderRadius: '50%'
                            }}
                        />
                        <span>Attempted</span>
                    </Box>
                </Grid>
                <Grid item>
                    <Box display="flex" alignItems="center">
                        <div
                            style={{
                                backgroundColor: 'yellow',
                                height: '20px',
                                width: '20px',
                                marginRight: '5px',
                                borderRadius: '50%'
                            }}
                        />
                        <span>Skipped</span>
                    </Box>
                </Grid>
                <Grid item>
                    <Box display="flex" alignItems="center">
                        <div
                            style={{
                                backgroundColor: 'gray',
                                height: '20px',
                                width: '20px',
                                marginRight: '5px',
                                borderRadius: '50%'
                            }}
                        />
                        <span>Not Attempted</span>
                    </Box>
                </Grid>
            </Grid>
            {/* ... (unchanged code for color legend) ... */}
        </div>
    );
};

export default QuestionPreview;
