/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable react/button-has-type */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Paper,
    Typography,
    Box,
    Card,
    CardContent,
    Button,
    Checkbox,
    FormControlLabel,
    TextareaAutosize,
    RadioGroup,
    Radio,
    FormGroup,
    CircularProgress,
    Modal,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import * as tf from '@tensorflow/tfjs';
import * as facemesh from '@tensorflow-models/facemesh';
import { drawMesh } from './utilities';
import TimerComponent from './Timer';
import QuestionPreview from './QuestionPreview';
import Webcam from 'react-webcam';
import axios from 'axios';

const ExamScreen = ({
    questionList,
    remainingDuration,
    currentQuestion,
    allAnswers,
    formik,
    setCurrentQuestion,
    handleFinalSubmition,
    state
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [screenShot, setscreenShot] = useState(null);
    const [studentdata, setstudentdata] = useState(null);
    const [screenShotPath, setscreenShotPath] = useState(null);
    const [loading, setLoading] = useState(false);
    console.log(state, 'state');
    const header = {
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
            'Content-Type': 'application/json'
        }
    };
    useEffect(() => {
        axios
            // eslint-disable-next-line no-useless-concat
            .get(`/v1/student/get/by-user`, header)
            .then((response) => {
                console.log(response.data.data, 'studentinformation');
                setstudentdata(response?.data?.data);
            });
    }, []);
    const handleNextQuestion = () => {
        setCurrentQuestion((prev) => prev + 1);
    };

    const handlePreviousQuestion = () => {
        setCurrentQuestion((prev) => Math.max(0, prev - 1));
    };

    const enterFullScreen = () => {
        const element = document.documentElement;

        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        enterFullScreen();
    };

    useEffect(() => {
        const handleFullScreenChange = () => {
            if (!document.fullscreenElement && !isModalOpen) {
                setIsModalOpen(true);
            }
        };

        const handleKeyDown = (event) => {
            if (event.key === 'Escape' && !isModalOpen) {
                setIsModalOpen(true);
            }
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('keydown', handleKeyDown);

        // Enter fullscreen mode when the component mounts
        enterFullScreen();

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen]);
    console.log(remainingDuration, 'remainingDuration');

    // PROCTORING
    const webcamRef = useRef(null);
    const canvasRef = useRef(null);
    const [person, setPerson] = useState([]);
    const [openNoPersonDialog, setOpenNoPersonDialog] = useState(false);
    const [openMultiplePersonsDialog, setOpenMultiplePersonsDialog] = useState(false);

    const handleCloseNoPersonDialog = () => {
        setOpenNoPersonDialog(false);
    };

    const handleCloseMultiplePersonsDialog = () => {
        setOpenMultiplePersonsDialog(false);
    };
    const runFacemesh = async () => {
        try {
            const net = await facemesh.load({
                inputResolution: { width: 720, height: 480 },
                scale: 0.8
            });
            console.log(net, 'NET');

            setInterval(() => {
                detect(net);
            }, 10000);
        } catch (error) {
            console.error('Error loading facemesh model:', error);
        }
    };

    const detect = async (net) => {
        try {
            if (webcamRef.current && webcamRef.current.video.readyState === 4) {
                const video = webcamRef.current.video;
                const videoWidth = webcamRef.current.video.videoWidth;
                const videoHeight = webcamRef.current.video.videoHeight;

                webcamRef.current.video.width = videoWidth;
                webcamRef.current.video.height = videoHeight;

                canvasRef.current.width = videoWidth;
                canvasRef.current.height = videoHeight;

                const face = await net.estimateFaces(video);
                setPerson(face);
                const ctx = canvasRef.current.getContext('2d');
                console.log(ctx, 'ctx');
                drawMesh(face, ctx);
            }
        } catch (error) {
            console.error('Error detecting faces:', error);
        }
    };

    runFacemesh();

    useEffect(() => {
        console.log(person?.length, 'facee');
        if (person?.length === 0) {
            setOpenNoPersonDialog(true);
        } else {
            handleCloseNoPersonDialog();
        }
        if (person?.length > 1) {
            setOpenMultiplePersonsDialog(true);
        } else {
            handleCloseMultiplePersonsDialog();
        }
    }, [person]);

    if (document.addEventListener) {
        document.addEventListener(
            'contextmenu',
            (e) => {
                e.preventDefault();
            },
            false
        );
    }

    const base64toFile = (base64String, filename) => {
        const splitString = base64String.split(',');
        const mimeType = splitString[0].match(/:(.*?);/)[1];
        const base64Data = atob(splitString[1]);

        const bytes = new Uint8Array(base64Data.length);
        for (let i = 0; i < base64Data.length; i++) {
            bytes[i] = base64Data.charCodeAt(i);
        }

        const blob = new Blob([bytes], { type: mimeType });

        return new File([blob], filename, { type: mimeType });
    };

    // const screenshotFile = base64toFile(screenshot, 'screenshot.png');

    const captureAndSendScreenshot = async () => {
        try {
            // Get the canvas element where the video is being rendered
            const screenshot = webcamRef.current.getScreenshot();
            console.log(screenshot, 'screenshotttt');

            const currentTime = new Date().toISOString(); // Get current time
            const screenshotFile = base64toFile(screenshot, `${currentTime}.png`);

            console.log(screenshotFile, 'payloadddd');

            if (screenShotPath) {
                const response = await axios.post(
                    `/v1/proctored-exam-snapshots/add?examId=${state?.id}&studentId=${state?.studentIds}`,
                    {
                        fileName: screenshotFile.name,
                        filePath: screenShotPath
                    },
                    header
                );
            }

            console.log(response, 'Screenshot captured and sent to the server.');
        } catch (error) {
            console.error('Error capturing and sending screenshot:', error);
        }
    };
    const collageID = localStorage.getItem('collegeId');
    const academicYearId = localStorage.getItem('academicYearId');

    const captureScreenshot = async () => {
        const pictureSrc = webcamRef.current.getScreenshot();
        const fileName = `${Math.floor(100000 + Math.random() * 900000)}.jpg`;
        const file = base64toFile(pictureSrc, fileName);
        console.log(file, studentdata, 'FILE');
        // console.log(screenShot, 'screenShot');
        setscreenShot(file);
        // if (screenShot !== null) {
        console.log('inside iffff');
        const formData = new FormData();
        formData.append('file', file);
        formData.append('collegeId', collageID || studentdata?.collegeId);
        formData.append('academicYearId', academicYearId || studentdata?.courseDetail?.academicYearId);
        try {
            const response = await axios.post(`/v1/utils/fileUpload/pre/signed`, formData, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'multipart/form-data'
                    // requestId: RequestId(Math.floor(1000 + Math.random() * 9999), formData + rendomRequestId)
                }
            });
            setscreenShotPath(response?.data?.data?.path);
            console.log(response, 'X0X0');
            captureAndSendScreenshot();
        } catch (err) {
            console.log(err);
        }
        // }
    };

    useEffect(() => {
        const screenshotInterval = setInterval(captureScreenshot, 10000); // 10 seconds
        return () => clearInterval(screenshotInterval);
    }, [screenShotPath]);

    return (
        <MainCard>
            <div style={{ display: 'flex', width: '400px', height: '400px', position: 'fixed', bottom: '0px', right: '15px' }}>
                {/* <Webcam style={{ width: '400px', height: '400px' }} /> */}
                <div style={{ position: 'relative', width: '400px', height: '400px' }}>
                    <Webcam
                        ref={webcamRef}
                        className="webcam"
                        style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
                    />
                    <canvas
                        ref={canvasRef}
                        className="webcam"
                        style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
                    />
                </div>
                <Dialog open={openNoPersonDialog} onClose={handleCloseNoPersonDialog}>
                    <DialogTitle>No Person Detected</DialogTitle>
                    <DialogContent>No person was detected in the webcam.</DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseNoPersonDialog}>Close</Button>
                    </DialogActions>
                </Dialog>

                {/* Dialog for multiple persons detected */}
                <Dialog open={openMultiplePersonsDialog} onClose={handleCloseMultiplePersonsDialog}>
                    <DialogTitle>Multiple Persons Detected</DialogTitle>
                    <DialogContent>Multiple persons were detected in the webcam.</DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseMultiplePersonsDialog}>Close</Button>
                    </DialogActions>
                </Dialog>
            </div>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" sx={{ mb: 1, display: 'grid', gridTemplateColumns: 'max-content 1fr' }}>
                                {questionList.length === currentQuestion ? (
                                    <Typography variant="h3" color="error">
                                        Exam Ended
                                    </Typography>
                                ) : (
                                    <>
                                        <Typography variant="h4">Q{currentQuestion + 1})&nbsp;</Typography>
                                        {questionList[currentQuestion] && (
                                            <>
                                                {
                                                    new DOMParser().parseFromString(
                                                        questionList[currentQuestion]?.questionText,
                                                        'text/html'
                                                    ).body.textContent
                                                }
                                            </>
                                        )}
                                    </>
                                )}
                            </Typography>

                            {questionList[currentQuestion]?.questionPaperType === 'MULTIPLE_CHOICE' && (
                                <RadioGroup>
                                    {questionList[currentQuestion]?.options.map((option, index) => (
                                        <FormControlLabel
                                            key={index}
                                            value={option.optionText}
                                            control={
                                                <Radio
                                                    name="selectMcqAnswer"
                                                    checked={option.optionId === formik.values.selectMcqAnswer}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`selectMcqAnswer`, option.optionId);
                                                    }}
                                                    value={formik.values.selectMcqAnswer}
                                                />
                                            }
                                            label={option.optionText}
                                        />
                                    ))}
                                </RadioGroup>
                            )}
                            {questionList[currentQuestion]?.questionPaperType === 'DESCRIPTIVE' && (
                                <textarea
                                    name="descriptiveAnswers"
                                    placeholder="Write your answer here..."
                                    onChange={formik.handleChange}
                                    onBlur={formik?.handleBlur}
                                    value={formik.values?.descriptiveAnswers}
                                    style={{ marginTop: '10px', height: '300px', width: '400px' }}
                                    onPaste={(e) => e.preventDefault()} // Prevent pasting into the textarea
                                />
                            )}
                            {console.log(questionList, 'questionList')}
                            {questionList[currentQuestion]?.questionPaperType === 'MULTI_ANSWER' && (
                                <FormGroup>
                                    {questionList[currentQuestion]?.options.map((option, index) => (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox
                                                    name="selectedOptions"
                                                    id="selectedOptions"
                                                    onChange={(e) => {
                                                        const selectedOptions = [...(formik.values.selectedOptions[currentQuestion] || [])];
                                                        if (e.target.checked) {
                                                            formik.setFieldValue(`selectedOptions[${currentQuestion}]`, []);
                                                            selectedOptions.push(option.optionId);
                                                        } else {
                                                            const index = selectedOptions.indexOf(option.optionId);
                                                            if (index !== -1) {
                                                                selectedOptions.splice(index, 1);
                                                            }
                                                        }
                                                        formik.setFieldValue(`selectedOptions[${currentQuestion}]`, selectedOptions);
                                                    }}
                                                    checked={
                                                        (formik.values?.selectedOptions &&
                                                            formik.values?.selectedOptions[currentQuestion]?.includes(option.optionId)) ||
                                                        false
                                                    }
                                                    value={option.optionId}
                                                />
                                            }
                                            label={option.optionText}
                                        />
                                    ))}
                                </FormGroup>
                            )}
                        </CardContent>
                    </Card>
                    <Grid sx={{ justifyContent: 'space-between' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handlePreviousQuestion}
                            disabled={currentQuestion === 0 || loading}
                            style={{ marginRight: '10px' }}
                        >
                            Back
                        </Button>

                        <Button variant="contained" color="primary" type="submit" disabled={loading}>
                            {loading ? <CircularProgress color="primary" label="Save & Next" size={24} /> : 'Save & Next '}
                        </Button>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Grid sx={{ display: 'flex', justifyContent: 'end', mb: 1 }}>
                        <Grid>
                            <TimerComponent handleFinalSubmition={handleFinalSubmition} duration={remainingDuration} />
                        </Grid>
                    </Grid>
                    <Paper elevation={1} style={{ padding: '25px', height: '90%', backgroundColor: '#E6ECF0' }}>
                        <QuestionPreview
                            questionList={questionList}
                            handleFinalSubmition={handleFinalSubmition}
                            attemptedQuestions={allAnswers}
                        />
                    </Paper>
                </Grid>
            </Grid>

            {isModalOpen && (
                <Modal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="fullscreen-modal-title"
                    aria-describedby="fullscreen-modal-description"
                >
                    <div
                        className="modal"
                        style={{
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            position: 'absolute',
                            backgroundColor: 'white',
                            padding: '20px',
                            borderRadius: '5px'
                        }}
                    >
                        <h2 id="fullscreen-modal-title">You can't leave fullscreen mode.</h2>
                        <p id="fullscreen-modal-description">Click OK to return to fullscreen mode.</p>
                        <Button variant="contained" onClick={handleCloseModal}>
                            OK
                        </Button>
                    </div>
                </Modal>
            )}
        </MainCard>
    );
};
export default ExamScreen;
