/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { Text, View, Image, StyleSheet, Color } from '@react-pdf/renderer';

import axios from 'axios';

const styles = StyleSheet.create({
    header: {
        color: 'white',
        fontSize: '26px',

        fontWeight: 'bold',
        top: '300',
        textAlign: 'center',
        textShadow: 'red 2px 5px'
    },
    tableContainer: {
        position: 'absolute',
        top: '57%',
        width: '250px',
        left: '40%',
        color: 'white',
        fontWeight: 'bold'

        // borderWidth: 1,
        // borderColor: "#000",
    },
    tableCell: {
        padding: '2%',
        borderBottom: '1px solid white',
        // backgroundColor: "#fff",
        color: 'white'
    },
    qr: {
        position: 'absolute',
        top: '130%',

        left: '70%',
        height: '100px',
        width: '100px'
    },

    boldTextContainer: {
        position: 'relative',
        display: 'inline-block'
    },
    boldText: {
        position: 'absolute',
        top: '10px',
        left: '10px',
        color: 'white'
    }
});
const BoldText = ({ children }) => (
    <Text style={styles.boldTextContainer}>
        <Text style={styles.boldText}>{children}</Text>
    </Text>
);
const AdmissonHeader = (props) => {
    console.log(props, 'admissionprop');
    const [dataset, setdataset] = useState([]);
    console.log(typeof dataset);
    useEffect(() => {
        axios
            // eslint-disable-next-line react/destructuring-assignment, react/prop-types
            .get(`/v1/applications/get-admission-form?courseDetailId=${props.courseDetailId}&&userId=${props.userId}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            })
            .then((result) => {
                setdataset([result.data.data], 'header');
                // localStorage.setItem('userId', response.data.data.userId);
            });
    }, []);
    console.log(dataset, 'data');
    return (
        <>
            {dataset.map((item) => (
                <>
                    <View style={styles.header}>
                        <BoldText>Admission Open</BoldText>
                        {/* <Text style={{ color: '#F2A516' }}> {item.courseDetail.academicYear.session.tag}</Text> */}
                    </View>
                    {/* <Image
                        src={`data:image/jpeg;base64,${item.studentPhoto}`}
                        alt="ff"
                        style={{ height: '180px', width: '150px', left: '10%', top: '47%' }}
                    /> */}

                    <View style={styles.tableContainer}>
                        <View>
                            <View>
                                <View style={styles.tableCell}>
                                    <Text>
                                        <Text>Name :</Text> {item.studentName}
                                    </Text>
                                </View>
                                <View style={styles.tableCell}>
                                    <Text style={{ paddingTop: '5%' }}>
                                        <Text>Program :</Text> {item.courseDetail.course.courseName}
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.qr}>
                                <Image src={`${process.env.PUBLIC_URL}/qr code.png`} />
                            </View>
                        </View>
                    </View>
                </>
            ))}
        </>
    );
};

export default AdmissonHeader;
