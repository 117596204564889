/* eslint-disable react/prop-types */
import React from 'react';
import Resource from './Resource';
import MainCard from 'ui-component/cards/MainCard';
import ResourceList from './ResourceList';

const ViewResources = ({
    values,
    setFieldValue,
    handleChange,
    errors,
    touched,
    studentsList,
    moduleId,
    setResourceList,
    resourceList,
    fetchResourcesByModuleId
}) => {
    console.log('Resoruces');
    return (
        <>
            <MainCard sx={{ m: 1 }}>
                <Resource
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    studentsList={studentsList}
                />
            </MainCard>
            <MainCard sx={{ m: 1 }}>
                <ResourceList
                    studentsList={studentsList}
                    moduleId={moduleId}
                    resourceList={resourceList}
                    setResourceList={setResourceList}
                    fetchResourcesByModuleId={fetchResourcesByModuleId}
                    values={values}
                    setFieldValue={setFieldValue}
                />
            </MainCard>
        </>
    );
};

export default ViewResources;
