import MainLayout from 'layout/MainLayout';
import { element } from 'prop-types';
import React, { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const ExamSetUP = Loadable(lazy(() => import('views/exam-setup')));
const ExamScreenShots = Loadable(lazy(() => import('views/exam-screenshorts')));
const QuestionType = Loadable(lazy(() => import('views/exam-setup/QuestionType')));
// const SystemCheck = Loadable(lazy(() => import('views/student/online-exam/SystemCheck')));

const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const ExamCreationRoute = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/exam',
            element: <ExamSetUP />
        },
        {
            path: '/exam/screen-shots',
            element: <ExamScreenShots />
        },
        {
            path: '/exam/addquestions',
            element: <QuestionType />
        }
    ]
};

export default ExamCreationRoute;
