import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const EventType = Loadable(lazy(() => import('views/application/eventmodule/eventtype/index')));
const EventList = Loadable(lazy(() => import('views/application/eventmodule/events/index')));
const EditEvent = Loadable(lazy(() => import('views/application/eventmodule/events/Edit')));
const EventTeamList = Loadable(lazy(() => import('views/application/eventmodule/eventteam/index')));
const roles = ['ROLE_STUDENT', 'ROLE_STUDENT_ADMISSION', 'ROLE_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN', 'ROLE_ADMIN'];

const EventRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/event/type',
            element: <EventType />
        },
        {
            path: '/event/list',
            element: <EventList />
        },
        {
            path: '/edit/event/:id',
            element: <EditEvent />
        },
        {
            path: '/event/team/list',
            element: <EventTeamList />
        }
    ]
};
export default EventRoutes;
