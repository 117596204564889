/* eslint-disable react-hooks/rules-of-hooks */
import './assets/scss/index.css';
// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

// auth provider
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
import axios from 'axios';
// import JWTContext, { JWTProvider } from 'contexts/JWTContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { useEffect } from 'react';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';
import { useLocation, useNavigate } from 'react-router-dom';
import { LMSRedirectionSetup } from 'menu-items/menus';

axios.defaults.baseURL = 'https://api.entraruniversity.com/api';
// axios.defaults.baseURL = 'http://192.168.68.121:5001/api';

// ==============================|| APP ||=============================== //

const App = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const { pathname } = location;
        if (pathname === '/') {
            navigate('/login');
        }
    }, [location, navigate]);
    return (
        <ThemeCustomization>
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <>
                                <Routes />
                                <Snackbar />
                                <LMSRedirectionSetup />
                            </>
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;
