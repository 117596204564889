/* eslint-disable react/prop-types */
import { Box, Modal, TextField, Typography } from '@mui/material';
import React from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '80%',
    borderRadius: '16px'
};

const CaptionModal = ({ open, setOpen, index, values, setFieldValue, handleChange }) => {
    const handleCLose = () => setOpen(false);
    return (
        <Modal open={open} onClose={handleCLose} aria-labelledby="caption-modal-by-index">
            <Box sx={style}>
                <Typography id="students-modal-title" variant="h4" component="h2" gutterBottom>
                    Write down caption for this image
                </Typography>
                <TextField
                    sx={{ m: 0.5 }}
                    name={`resourceContents.${index}.resourceCaption`}
                    value={values.resourceContents[index].resourceCaption}
                    onChange={handleChange}
                    label="Resource Caption"
                    fullWidth
                    multiline
                    minRows={1}
                    maxRows={5}
                />
            </Box>
        </Modal>
    );
};

export default CaptionModal;
