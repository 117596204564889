import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';

const ModalComponent = ({ isOpen, onClose, title, setContent, content, onConfirm, textarea, isLoading }) => {
    const dialogStyle = {
        width: '400px',
        maxWidth: '500px'
    };
    const renderContent = () => {
        if (textarea) {
            return (
                <TextField
                    label="Enter Text"
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    style={{ padding: '1%', marginTop: '7px' }}
                    InputLabelProps={{
                        style: { fontSize: '15px', zIndex: 1 }
                    }}
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                />
            );
        }

        return content;
    };

    return (
        <div>
            <Dialog open={isOpen}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent style={{ ...dialogStyle, fontSize: '14px' }}>{renderContent()}</DialogContent>

                <DialogActions style={{ padding: '2%' }}>
                    {isLoading ? (
                        <CircularProgress fontSize="small" sx={{ color: '#00326D' }} />
                    ) : (
                        <Button onClick={onConfirm} variant="contained">
                            Confirm
                        </Button>
                    )}
                    <Button onClick={onClose} variant="contained" color="error">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ModalComponent;
