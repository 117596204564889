import MainLayout from 'layout/MainLayout';
import React, { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const NewAssessmentSetup = Loadable(lazy(() => import('views/assessment-new')));
const QuestionType = Loadable(lazy(() => import('views/assessment-new/QuestionType')));
const UploadAssignment = Loadable(lazy(() => import('views/assessment-new/UploadAssigment')));
// const AttempteAssignment = Loadable(lazy(() => import('views/assessment-new/attempted-assignments')));
const AnswerSheet = Loadable(lazy(() => import('views/assessment-new/attempted-assignments/AnswerSheet')));

const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const MainAssignmentRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/add/assignment',
            element: <NewAssessmentSetup />
        },

        {
            path: '/assignment/addquestions',
            element: <QuestionType />
        },
        // {
        //     path: '/admin/assignment/attempted/:assessmentId/:subjectId',
        //     element: <AttempteAssignment />
        // },
        {
            path: '/admin/assignment/answer-sheet',
            element: <AnswerSheet />
        },
        {
            path: '/student/assignment/:assignmentId/:courseDetailId',
            element: <UploadAssignment />
        }
    ]
};

export default MainAssignmentRoutes;
