import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { Formik } from 'formik';
import AddResources from './AddResources';
import ViewResources from './ViewResources';
import { Button, Grid, Typography } from '@mui/material';
import axios from 'axios';
import useMessageDispatcher from 'hooks/useMessageDispatcher';
import { useDispatch } from 'react-redux';

const validationSchema = Yup.object().shape({
    // resourceTitle: Yup.string().required('Resource title is required'),
    // resourceContents: Yup.array().of(
    //     Yup.object().shape({
    //         resourceContent: Yup.string().required('Resource content is required'),
    //         resourceCaption: Yup.string().notRequired()
    //     })
    // )
});

const LessonPlanResources = () => {
    const [renderType, setRenderType] = useState('ADD');
    const [studentsList, setStudentsList] = useState([]);
    const [resourceList, setResourceList] = useState([]);

    const location = useLocation();
    const { state } = location;
    console.log(location, 'statestate');
    const { batchId, subjectId, courseId, moduleId } = state;

    const showMessage = useMessageDispatcher();
    const dispatch = useDispatch();

    const initialValues = {
        id: '',
        moduleId: state.moduleId,
        resourceTitle: '',
        resourceType: 'TEXT',
        seq: 0,
        multipleResources: false,
        resourceCaption: '',
        resourceContent: '',
        shareToStudents: false,
        allStudents: false,
        studentIds: [],
        fileContents: null,
        resourceContents: [
            {
                resourceContent: '',
                resourceCaption: '',
                fileContents: null,
                resourceType: 'TEXT'
            }
        ]
    };

    console.log(state, 'Resoruces');

    const fetchStudentsList = async () => {
        try {
            const params = { sessionId: batchId, subjectId, courseId, moduleId };
            const response = await axios.get(`/v1/student/mini/list/filter`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                },
                params
            });
            if (response.status === 200) {
                if (response.data.code !== 403) {
                    const { StudentList } = response.data;
                    setStudentsList(StudentList);
                } else {
                    showMessage({ message: response.message, color: 'error' });
                }
            }
        } catch (error) {
            if (!error.response) {
                showMessage({ message: error.message, color: 'error' });
            } else {
                showMessage({ message: error.response.data.Msg, color: 'error' });
            }
        }
    };

    const fetchResourcesByModuleId = async (id) => {
        try {
            // const params = {
            //     moduleId: id
            // };
            const response = await axios.get(`/v1/subjects/lesson-plans/module/resources/by-module/get?moduleId=${moduleId}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                if (response.data.code !== 403) {
                    const { resources } = response.data;
                    const sortedResources = resources.sort((a, b) => a.seq - b.seq);
                    setResourceList(sortedResources);
                } else {
                    showMessage({ message: response.message, color: 'error' });
                }
            }
        } catch (error) {
            if (!error.response) {
                showMessage({ message: error.message, color: 'error' });
            } else {
                showMessage({ message: error.response.data.Msg, color: 'error' });
            }
        }
    };

    const onSubmit = async (data, { resetForm }) => {
        // return;
        // eslint-disable-next-line no-unreachable
        const payload = data;
        try {
            const response = await axios.post(`/v1/subjects/lesson-plans/module/resources/add-update`, payload, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                if (response.data.code !== 403) {
                    showMessage({ message: response.data.Msg });
                    fetchResourcesByModuleId(moduleId);
                    resetForm();
                } else {
                    showMessage({ message: response.message, color: 'error' });
                }
            }
        } catch (error) {
            if (!error.response) {
                showMessage({ message: error.message, color: 'error' });
            } else {
                showMessage({ message: error.response.data.Msg, color: 'error' });
            }
        }
    };

    useEffect(() => {
        fetchStudentsList();
        fetchResourcesByModuleId(moduleId);
    }, [moduleId]);

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                {({ values, setFieldValue, handleChange, errors, touched, setFieldTouched }) => (
                    <ViewResources
                        values={values}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        setRenderType={setRenderType}
                        errors={errors}
                        touched={touched}
                        studentsList={studentsList}
                        moduleId={moduleId}
                        fetchResourcesByModuleId={fetchResourcesByModuleId}
                        resourceList={resourceList}
                        setResourceList={setResourceList}
                    />
                )}
            </Formik>
        </>
    );
};

export default LessonPlanResources;
