/* eslint-disable react/prop-types */
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '80%',
    borderRadius: '16px'
};

const LinkCreationModal = ({ open, setOpen, index = null, values, setFieldValue, handleChange }) => {
    const [content, setContent] = useState('');
    const [caption, setCaption] = useState('');
    const onClose = () => setOpen(false);

    const handleLinkSubmission = () => {
        const prevContents = values?.resourceContents?.filter((c) => c.resourceContent !== '');
        const newContent = {
            resourceContent: content,
            resourceCaption: caption,
            resourceType: 'LINK'
        };

        if (index !== null) {
            const shallowPrevContents = prevContents;
            shallowPrevContents[index] = newContent;
            setFieldValue(`resourceContents`, shallowPrevContents);
        } else {
            setFieldValue(`resourceContents`, [...prevContents, newContent]);
        }
        onClose();
    };

    useEffect(() => {
        if (index !== null) {
            const { resourceContent, resourceCaption } = values.resourceContents[index];
            setContent(resourceContent);
            setCaption(resourceCaption);
        }
    }, [index]);

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={style}>
                <Typography id="students-modal-title" variant="h4" component="h2" gutterBottom>
                    Add Link
                </Typography>
                <TextField sx={{ m: 0.5 }} value={content} onChange={(e) => setContent(e.target.value)} label="Text to display" fullWidth />
                <TextField sx={{ m: 0.5 }} value={caption} onChange={(e) => setCaption(e.target.value)} label="Web Address" fullWidth />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button variant="contained" onClick={handleLinkSubmission}>
                        Submit
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default LinkCreationModal;
