/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import {
    TextField,
    Grid,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Button,
    IconButton,
    Tooltip,
    InputAdornment,
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    ButtonGroup
} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import { Form } from 'formik';
import axios from 'axios';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InfoIcon from '@mui/icons-material/Info';
import StudentsListModal from './StudentsListModal';
import TitleIcon from '@mui/icons-material/Title';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import AddLinkIcon from '@mui/icons-material/AddLink';
import ListIcon from '@mui/icons-material/List';
import CaptionModal from './CaptionModal';
import BulletPointsModal from './BulletPointsModal';
import LinkCreationModal from './LinkCreationModal';
import { Link } from 'react-router-dom';

const Resource = ({ values, handleChange, errors, touched, setFieldValue, studentsList }) => {
    const [openStudentsModal, setOpenStudentsModal] = useState(false);
    const [openCaptionModal, setOpenCaptionModal] = useState(false);
    const [openBulletPointsModal, setOpenBulletPointsModal] = useState(false);
    const [openLinkModal, setOpenLinkModal] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(null);

    const imageInputRef = useRef(null);
    const pdfInputRef = useRef(null);

    const handleOnPDFSelection = () => {
        pdfInputRef.current.click();
    };

    const handleImageSelection = () => {
        imageInputRef.current.click();
    };

    const handleTextSelection = () => {
        const newTextField = {
            resourceContent: '',
            resourceCaption: '',
            resourceType: 'TEXT'
        };
        setFieldValue('resourceContents', [...values?.resourceContents, newTextField]);
    };

    const handleAddList = () => {
        setCurrentIndex(null);
        setOpenBulletPointsModal(true);
    };

    const handleAddLink = () => {
        setCurrentIndex(null);
        setOpenLinkModal(true);
    };

    const actions = [
        { icon: <ImageIcon />, name: 'Image', function: handleImageSelection, toolTipMsg: ' Add Image' },
        { icon: <PictureAsPdfIcon />, name: 'PDF', function: handleOnPDFSelection, toolTipMsg: 'Add PDF' },
        { icon: <TitleIcon />, name: 'Text', function: handleTextSelection, toolTipMsg: 'Add Text' },
        { icon: <ListIcon />, name: 'Text', function: handleAddList, toolTipMsg: 'Add List' },
        { icon: <AddLinkIcon />, name: 'Text', function: handleAddLink, toolTipMsg: 'Add Link' }
    ];

    const uploadFile = async (event, type) => {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append('fileName', event.target.files[0].name);
        formData.append('instituteId', localStorage.getItem('instituteId'));
        formData.append('folder ', 'FACULTY_RESOURCES');
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`
            }
        };
        axios.post('/v1/utils/fileUpload/pre/signed/generic/institute', formData, config).then((response) => {
            const { originName, path, preSignedUrl } = response.data.data;
            setFieldValue(`resourceType`, 'IMAGE');

            const prevContents = values?.resourceContents?.filter((c) => c.resourceContent !== '');
            const newContent = {
                resourceContent: path,
                resourceType: type,
                fileContent: {
                    path,
                    preSignedUrl,
                    originName
                }
            };

            setFieldValue(`resourceContents`, [...prevContents, newContent]);
        });
    };

    const deleteByIndex = (index) => {
        const filteredAfterDeletion = values.resourceContents.filter((_, i) => i !== index);
        setFieldValue('resourceContents', filteredAfterDeletion);
    };

    const handleImageCaptionOpen = (index) => {
        setCurrentIndex(index);
        setOpenCaptionModal(true);
    };
    const handleEditList = (index) => {
        setCurrentIndex(index);
        setOpenBulletPointsModal(true);
    };

    const handleLinkEdit = (index) => {
        setCurrentIndex(index);
        setOpenLinkModal(true);
    };

    return (
        <>
            <Form>
                <Grid md={12}>
                    <Grid item md={12} sx={{ m: 0.5 }}>
                        <TextField
                            name="resourceTitle"
                            value={values.resourceTitle}
                            onChange={handleChange}
                            label="Title"
                            fullWidth
                            error={touched.resourceTitle && Boolean(errors.resourceTitle)}
                            helperText={touched.resourceTitle && errors.resourceTitle}
                        />
                    </Grid>
                    <Grid item md={12} sx={{ m: 0.5 }}>
                        {values?.resourceContents?.map((item, index) => (
                            <>
                                {item?.resourceType === 'TEXT' && (
                                    <TextField
                                        sx={{ m: 0.5 }}
                                        name={`resourceContents.${index}.resourceContent`}
                                        value={item.resourceContent}
                                        onChange={handleChange}
                                        label="Resource Content"
                                        fullWidth
                                        multiline
                                        minRows={3}
                                        // error={
                                        //     touched.resourceContents[0].resourceContent && Boolean(errors.resourceContents[0].resourceContent)
                                        // }
                                        // helperText={touched.resourceContents[0].resourceContent && errors.resourceContents[0].resourceContent}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton sx={{ color: '#FF0000' }} onClick={() => deleteByIndex(index)} edge="start">
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                )}

                                {item?.resourceType === 'IMAGE' && (
                                    <ImageList
                                        sx={{
                                            width: '50%',
                                            height: '50%',
                                            marginLeft: '25%',
                                            marginRight: '25%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexWrap: 'wrap'
                                        }}
                                        cols={2}
                                        gap={8}
                                    >
                                        <ImageListItem
                                            sx={{ display: 'flex', justifyContent: 'center', width: 'fit-content' }}
                                            key={values.img}
                                        >
                                            <img
                                                srcSet={`${item?.fileContent?.preSignedUrl}`}
                                                src={`${item?.fileContent?.preSignedUrl}`}
                                                alt={item?.fileContent?.originName}
                                                loading="lazy"
                                                style={{
                                                    maxWidth: '100%',
                                                    maxHeight: '100%',
                                                    objectFit: 'cover',
                                                    display: 'block',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            />
                                            <ImageListItemBar
                                                title={item?.fileContent?.originName}
                                                actionIcon={
                                                    <>
                                                        <Tooltip title="Delete this image">
                                                            <IconButton sx={{ color: '#FF0000' }} onClick={() => deleteByIndex(index)}>
                                                                <DeleteForeverIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="See caption for this image">
                                                            <IconButton
                                                                sx={{ color: '#3399ff' }}
                                                                onClick={() => handleImageCaptionOpen(index)}
                                                            >
                                                                <InfoIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </>
                                                }
                                            />
                                        </ImageListItem>
                                    </ImageList>
                                )}

                                {item?.resourceType === 'PDF' && (
                                    <Box
                                        sx={{
                                            width: 1,
                                            height: 'auto',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            p: 2
                                        }}
                                    >
                                        <embed
                                            src={`${item?.fileContent?.preSignedUrl}#view=FitH`}
                                            type="application/pdf"
                                            width="80%"
                                            height="400px"
                                            style={{ maxWidth: '100%', maxHeight: '100%', border: 'none' }}
                                        />
                                        <IconButton aria-label="edit" size="small" onClick={() => deleteByIndex(index)}>
                                            <DeleteForeverIcon sx={{ color: '#FF0000' }} fontSize="inherit" />
                                        </IconButton>
                                    </Box>
                                )}

                                {item?.resourceType === 'LIST' && (
                                    <>
                                        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', position: 'relative' }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 1 }}>
                                                <>
                                                    <Typography variant="h6">{item?.resourceCaption}</Typography>
                                                </>
                                                <ButtonGroup>
                                                    <IconButton aria-label="edit" size="small" onClick={() => deleteByIndex(index)}>
                                                        <DeleteForeverIcon sx={{ color: '#FF0000' }} fontSize="inherit" />
                                                    </IconButton>
                                                    <IconButton aria-label="edit" size="small" onClick={() => handleEditList(index)}>
                                                        <EditIcon fontSize="inherit" />
                                                    </IconButton>
                                                </ButtonGroup>
                                            </Box>
                                            <Box sx={{ overflow: 'auto', maxHeight: '200px' }}>
                                                <List component="nav" aria-label="mailbox folders">
                                                    {JSON.parse(item?.resourceContent).map((listItem, index) => (
                                                        <ListItem key={index} divider>
                                                            <ListItemText primary={`* ${listItem}`} />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Box>
                                        </Box>
                                    </>
                                )}

                                {item?.resourceType === 'LINK' && (
                                    <Grid sx={{ m: 1, justifyContent: 'center' }}>
                                        <Link to={item.resourceCaption}>{item.resourceContent}</Link>
                                        <ButtonGroup>
                                            <IconButton aria-label="edit" size="small" onClick={() => deleteByIndex(index)}>
                                                <DeleteForeverIcon sx={{ color: '#FF0000' }} fontSize="inherit" />
                                            </IconButton>
                                            <IconButton aria-label="edit" size="small" onClick={() => handleLinkEdit(index)}>
                                                <EditIcon fontSize="inherit" />
                                            </IconButton>
                                        </ButtonGroup>
                                    </Grid>
                                )}
                            </>
                        ))}
                    </Grid>
                </Grid>
                <input
                    type="file"
                    ref={imageInputRef}
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={(event) => uploadFile(event, 'IMAGE')}
                />
                <input
                    type="file"
                    ref={pdfInputRef}
                    style={{ display: 'none' }}
                    accept="application/pdf"
                    onChange={(event) => uploadFile(event, 'PDF')}
                />
                <Grid md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <>
                        {actions.map((action) => (
                            <Tooltip title={action.toolTipMsg}>
                                <IconButton size="small" onClick={action.function}>
                                    {action.icon}
                                </IconButton>
                            </Tooltip>
                        ))}
                    </>
                </Grid>
                <Grid md={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                    <IconButton onClick={() => setOpenStudentsModal(true)}>
                        <ShareIcon />
                    </IconButton>
                    <Button type="submit" variant="contained">
                        Submit
                    </Button>
                </Grid>
                {openStudentsModal && (
                    <StudentsListModal
                        open={openStudentsModal}
                        setOpen={setOpenStudentsModal}
                        studentsList={studentsList}
                        values={values}
                        setFieldValue={setFieldValue}
                    />
                )}

                {openCaptionModal && (
                    <CaptionModal
                        open={openCaptionModal}
                        setOpen={setOpenCaptionModal}
                        index={currentIndex}
                        values={values}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                    />
                )}
                {openBulletPointsModal && (
                    <BulletPointsModal
                        open={openBulletPointsModal}
                        setOpen={setOpenBulletPointsModal}
                        values={values}
                        setFieldValue={setFieldValue}
                        index={currentIndex}
                    />
                )}
                {openLinkModal && (
                    <LinkCreationModal
                        open={openLinkModal}
                        setOpen={setOpenLinkModal}
                        values={values}
                        setFieldValue={setFieldValue}
                        index={currentIndex}
                    />
                )}
            </Form>
        </>
    );
};

export default Resource;
