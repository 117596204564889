import { useState, useEffect, memo } from 'react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Typography, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import menuItem from 'menu-items';
import NavGroup from './NavGroup';
import LAYOUT_CONST from 'constant';
import { HORIZONTAL_MAX_ITEM } from 'config';
import useConfig from 'hooks/useConfig';

const filterItems = (items, searchQuery) => {
    if (!searchQuery) return items;
    const query = searchQuery.toLowerCase();

    const filterRecursive = (items) =>
        items.reduce((acc, item) => {
            const matches = item?.id?.toLowerCase().includes(query);
            const filteredChildren = item?.children ? filterRecursive(item.children) : [];
            if (matches || filteredChildren.length) {
                acc.push({
                    ...item,
                    children: filteredChildren.length ? filteredChildren : []
                });
            }
            return acc;
        }, []);

    return filterRecursive(items);
};

const MenuList = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState(menuItem.items);

    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const { layout } = useConfig();

    useEffect(() => {
        const newFilteredItems = filterItems(menuItem.items, searchQuery);
        setFilteredItems(newFilteredItems);
    }, [searchQuery]);

    const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? HORIZONTAL_MAX_ITEM : null;

    const navItems = filteredItems.map((item, index) => {
        let lastItemId = null;
        let remItems = [];

        if (lastItem && index === lastItem - 1) {
            lastItemId = item.id;
            remItems = filteredItems.slice(lastItem).map((remItem) => ({
                title: remItem.title,
                elements: remItem.children
            }));
        }

        return (
            <NavGroup key={item.id} item={item} lastItem={lastItem} remItems={remItems} lastItemId={lastItemId} searchQuery={searchQuery} />
        );
    });

    return (
        <>
            <Box sx={{ px: 2 }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </Box>
            {navItems}
        </>
    );
};

export default memo(MenuList);
