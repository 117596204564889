import MainLayout from 'layout/MainLayout';
import React, { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const AssessmentForAssignment = Loadable(lazy(() => import('views/assest-assignments')));
const AssignmentsForEvaluation = Loadable(lazy(() => import('views/assest-assignments/assignment-evaluation')));
const AnswerSheet = Loadable(lazy(() => import('views/assest-assignments/assignment-evaluation/AnswerSheet')));

const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const AssessmentEvaluteRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/evaluate/exam',
            element: <AssessmentForAssignment />
        },
        {
            path: '/evaluate/assignment',
            element: <AssessmentForAssignment />
        },
        {
            path: '/individual-student/assignments',
            element: <AssignmentsForEvaluation />
        },
        {
            path: '/assignment/answer-sheet',
            element: <AnswerSheet />
        }
    ]
};

export default AssessmentEvaluteRoutes;
