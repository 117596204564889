/* eslint-disable react/prop-types */
import {
    Grid,
    Modal,
    Typography,
    TextField,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    InputAdornment
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LoupeIcon from '@mui/icons-material/Loupe';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '500px',
    overflow: 'auto',
    borderRadius: '16px'
};

const BulletPointsModal = ({ open, setOpen, values, setFieldValue, index }) => {
    const [listItems, setListItems] = useState([]);
    const [currentItem, setCurrentItem] = useState('');
    const [title, setTitle] = useState('');

    const handleAddItem = () => {
        if (currentItem.trim() !== '') {
            setListItems([...listItems, currentItem.trim()]);
            setCurrentItem('');
        }
    };

    const handleRemoveItem = (index) => {
        setListItems(listItems.filter((_, i) => i !== index));
    };

    const handleSubmitList = () => {
        const stringFromArray = JSON.stringify(listItems);
        console.log(stringFromArray, 'stringFromArray');
        const currObj = {
            resourceContent: stringFromArray,
            resourceCaption: title,
            resourceType: 'LIST'
        };

        if (index) {
            const modifiedValues = values.resourceContents;
            modifiedValues[index] = currObj;
            setFieldValue('resourceContents', modifiedValues);
        } else {
            setFieldValue('resourceContents', [...values.resourceContents, currObj]);
        }

        setOpen(false);
    };

    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (index) {
            const parsedArray = JSON.parse(values.resourceContents[index].resourceContent);
            console.log(parsedArray, 'parsedArray');
            const listTitle = values.resourceContents[index].resourceCaption;
            setListItems(parsedArray);
            setTitle(listTitle);
        }
    }, [index]);

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="list-modal-label">
            <Grid sx={style}>
                <Typography id="students-modal-title" variant="h4" component="h2" gutterBottom>
                    Add List of points
                </Typography>
                <Grid>
                    <TextField
                        fullWidth
                        sx={{ mb: 1 }}
                        variant="outlined"
                        name="resourceTitle"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        label="Title"
                    />
                </Grid>
                <Grid>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Add a bullet point"
                        value={currentItem}
                        onChange={(e) => setCurrentItem(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') handleAddItem();
                        }}
                        sx={{ mb: 2 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => handleAddItem()} edge="start">
                                        <LoupeIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>

                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {listItems.map((item, index) => (
                        <ListItem
                            key={index}
                            secondaryAction={
                                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveItem(index)}>
                                    <CloseIcon />
                                </IconButton>
                            }
                        >
                            <ListItemText primary={`• ${item}`} />
                        </ListItem>
                    ))}
                </List>
                <Grid md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={handleSubmitList} variant="contained" sx={{ mt: 2 }}>
                        Submit List
                    </Button>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default BulletPointsModal;
